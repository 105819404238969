import {useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {useFormik} from 'formik'
import { useIntl } from 'react-intl'
import { requestPassword } from '../core/_requests'

const initialValues = {
  email: ''
}

const forgotPasswordSchema = Yup.object().shape({
  // password: Yup.string().min(10).required('Password is required'),
  // confirmPassword: Yup.string().min(10).required('Password confirmation is required.').oneOf([Yup.ref('password')], 'The password and its confirmation are not the same.'),
  email: Yup.string()
    .email('Wrong email format')
    .required('Email is required')
})

export function ForgotPassword() {
  const intl = useIntl();
  const [loading, setLoading] = useState(false);
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined);
  const [apiResponse, setApiResponse] = useState("");
  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: (values, {setSubmitting}) => {
      setLoading(true)
      setHasErrors(undefined)
      setTimeout(() => {
        window['showToastOnError'] = false;
        requestPassword(values.email)
          .then(() => {
            setHasErrors(false);
            setLoading(false);
            setApiResponse("");
          })
          .catch((error) => {
            const errorMessageFromAPI:string = error.response?.data?.message;
            if (errorMessageFromAPI) {
              setApiResponse(errorMessageFromAPI);
            }
            setHasErrors(true)
            setLoading(false)
            setSubmitting(false)
          })
      }, 1000)
    },
  })

  return (
    <form
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      noValidate
      id='kt_login_password_reset_form'
      onSubmit={formik.handleSubmit}
    >
      <div className='text-center mb-10'>
        {/* begin::Title */}
        <h1 className='text-dark fw-bolder mb-3'>
          {intl.formatMessage({id: 'AUTH.FORGOT.TITLE'})}
        </h1>
        {/* end::Title */}

        {/* begin::Link */}
        <div className='text-gray-500 fw-semibold fs-6'>
          {intl.formatMessage({id: 'AUTH.FORGOT.DESC'})}        
        </div>
        {/* end::Link */}
      </div>

      {/* begin::Form group */}
      <div className='fv-row mb-10'>
        <input
          type='email'
          placeholder={intl.formatMessage({id: 'AUTH.LOGIN.EMAIL_PLACEHOLDER'})} 
          autoComplete='off'
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control bg-transparent mb-5',
            {
              'is-invalid': formik.touched.email && formik.errors.email,
            },
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
        />
         {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container mb-3'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.email}</span>
            </div>
          </div>
        )}
        {/* <p className='fs-6' style={{color: '#99A1B7'}}>
          {intl.formatMessage({id: 'AUTH.FORGOT.EMAIL_TERMS'})}
        </p> */}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      {/* <div className='fv-row mb-10'>
        <input
          type='password'
          placeholder={intl.formatMessage({id: 'AUTH.LOGIN.PASSWORD_PLACEHOLDER'})} 
          autoComplete='off'
          {...formik.getFieldProps('password')}
          className={clsx(
            'form-control bg-transparent mb-5',
            {
              'is-invalid': formik.touched.password && formik.errors.password,
            },
            {
              'is-valid': formik.touched.password && !formik.errors.password,
            }
          )}
        />
         {formik.touched.password && formik.errors.password && (
          <div className='fv-plugins-message-container mb-3'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.password}</span>
            </div>
          </div>
        )}
        <p className='fs-6' style={{color: '#99A1B7'}}>
          {intl.formatMessage({id: 'AUTH.FORGOT.PASSWORD_TERMS'})}
        </p>
      </div> */}
      {/* end::Form group */}

       {/* begin::Form group */}
       {/* <div className='fv-row mb-10'>
        <input
          type='password'
          placeholder={intl.formatMessage({id: 'AUTH.LOGIN.PASSWORD_REPEAT_PLACEHOLDER'})} 
          autoComplete='off'
          {...formik.getFieldProps('confirmPassword')}
          className={clsx(
            'form-control bg-transparent mb-4',
            {
              'is-invalid': formik.touched.confirmPassword && formik.errors.confirmPassword,
            },
            {
              'is-valid': formik.touched.confirmPassword && !formik.errors.confirmPassword,
            }
          )}
        />
        {formik.touched.confirmPassword && formik.errors.confirmPassword && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.confirmPassword}</span>
            </div>
          </div>
        )}
      </div> */}
      {/* end::Form group */}

      {/* begin::Title */}
      {hasErrors === true && (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>
            {apiResponse && <span>{apiResponse}</span>}
            {!apiResponse && <span>{intl.formatMessage({id: 'AUTH.FORGOT.ERRORS'})}</span>}
          </div>
        </div>
      )}

      {hasErrors === false && (
        <div className='mb-10 bg-light-info p-8 rounded'>
          <div className='text-info'>
            {intl.formatMessage({id: 'AUTH.FORGOT.SUCCESS'})} 
          </div>
        </div>
      )}
      {/* end::Title */}

      {/* begin::Form group */}
      <div className='d-grid mb-10'>
        <button
          type='submit'
          className='btn btn-primary'
          disabled={formik.isSubmitting}
        >
          {!loading && 
            <span className='indicator-label'>
              {intl.formatMessage({id: 'AUTH.FORGOT.BUTTON'})}
            </span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              {intl.formatMessage({id: 'FETCH.LOADING'})}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
      {/* end::Form group */}
    </form>
  )
}
