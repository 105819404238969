import {Modal, Button, Form} from 'react-bootstrap'
import {Formik} from 'formik'
import {useIntl} from 'react-intl'
import { RadioButtonBooleanField } from '../../../app/components/RadioButtonBooleanField';

export function OrderPreferences({show, onHide, onSubmit}:{show:any, onHide:any, onSubmit:any}) {
  const intl = useIntl();
  return (
    <Modal show={show} onHide={onHide} dialogClassName='modal-dialog modal-dialog-centered'>
      <Modal.Header closeButton>
        <h2>{intl.formatMessage({id: 'ORDERS.FINISH_MODAL_TITLE'})}</h2>
      </Modal.Header>
      <Modal.Body className='modal-body py-lg-10 px-lg-20'>
        <Formik
          initialValues={{completeOrder: false, splitPieces: false}}
          onSubmit={(values) => {
            onSubmit(values)
            onHide()
          }}
        >
          {({handleSubmit, values, setValues}) => (
            <Form onSubmit={handleSubmit}>
              <p
                className='fs-4'
                dangerouslySetInnerHTML={{
                  __html: intl.formatMessage({id: 'ORDERS.FINISH_MODAL_COMPLETE_ORDER'}),
                }}
              ></p>
              <Form.Group className='d-flex align-items-center mb-5'>
                <RadioButtonBooleanField
                  name='completeOrder'
                  value={true}
                  checked={values.completeOrder}
                  setValues={setValues}
                  controlId='completeOrderYES'
                />
                <Form.Label className='mb-0 mx-2 fs-4' htmlFor='completeOrderYES'>
                  {intl.formatMessage({id: 'ORDERS.FINISH_MODAL_COMPLETE_ORDER_YES'})}
                </Form.Label>
              </Form.Group>
              <Form.Group className='d-flex align-items-center mb-5'>
                <RadioButtonBooleanField
                  name='completeOrder'
                  value={false}
                  checked={!values.completeOrder}
                  setValues={setValues}
                  controlId='completeOrderNO'
                />
                <Form.Label className='mb-0 mx-2 fs-4' htmlFor='completeOrderNO'>
                  {intl.formatMessage({id: 'ORDERS.FINISH_MODAL_COMPLETE_ORDER_NO'})}
                </Form.Label>
              </Form.Group>

              <p
                className='fs-4'
                style={{marginTop:'4rem'}}
                dangerouslySetInnerHTML={{
                  __html: intl.formatMessage({id: 'ORDERS.FINISH_MODAL_SPLIT_PIECES'}),
                }}
              ></p>
              <Form.Group className='d-flex align-items-center mb-2'>
                <RadioButtonBooleanField
                  name='splitPieces'
                  value={false}
                  checked={!values.splitPieces}
                  setValues={setValues}
                  controlId='splitPiecesNO'
                />
                <Form.Label className='mb-0 mx-2 fs-4' htmlFor='splitPiecesNO'>
                  {intl.formatMessage({id: 'ORDERS.FINISH_MODAL_SPLIT_PIECES_NO'})}
                </Form.Label>
              </Form.Group>
              <Form.Group className='d-flex align-items-center mb-2'>
                <RadioButtonBooleanField
                  name='splitPieces'
                  value={true}
                  checked={values.splitPieces}
                  setValues={setValues}
                  controlId='splitPiecesYES'
                />
                <Form.Label className='mb-0 mx-2 fs-4' htmlFor='splitPiecesYES'>
                  {intl.formatMessage({id: 'ORDERS.FINISH_MODAL_SPLIT_PIECES_YES'})}
                </Form.Label>
              </Form.Group>
              <div className='d-flex justify-content-end'>
              <Button variant='primary' type='submit' className='btn btn-primary btm-sm mt-8'>
                {intl.formatMessage({id: 'BUTTON.ACCEPT'})}
              </Button>
              </div>
              
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  )
}
