import {Modal, Button, Form} from 'react-bootstrap'
import {Formik, Field} from 'formik'
import {useIntl} from 'react-intl'
import {useQuery} from 'react-query'
import { editCategory, requestCategory } from '../../requests/requests'
import { CategoryName, CategoryWithLanguages, EditCategoryModel, Language } from '../../models/models'

function EditCategoryModal({id, show, onHide, refetchNews}) {
  const intl = useIntl()
  const {data: datax, refetch: refetchCategory} = useQuery<CategoryWithLanguages>(
    [`editCategory${id}`],
    () => {
      return requestCategory(id).then(({data: x}) => {
        return x
      })
    },
    {refetchOnWindowFocus: false, staleTime: 1000 * 60 * 5}
  )

  return (
    <Modal show={show} onHide={onHide} dialogClassName='modal-dialog modal-dialog-centered'>
      <Modal.Header closeButton>
        <h2 className='text-black'>
          {intl.formatMessage({id: 'CATEGORIES.MODAL_CATEGORIES.EDIT_CATEGORIES_PAGE_TITLE'})}
        </h2>
      </Modal.Header>
      <Modal.Body className='modal-body py-lg-10 px-lg-20'>
        <Formik
          initialValues={{
            id: id,
            nameES: datax?.name.find((x) => x.language === Language.Spanish)?.value,
            nameEN: datax?.name.find((x) => x.language === Language.English)?.value,
          }}
          onSubmit={({id, nameES, nameEN}) => {
            const editCategoryObj = {
              id,
              name: [
                {value: nameEN, language: Language.English} as CategoryName,
                {value: nameES, language: Language.Spanish} as CategoryName,
              ],
            } as EditCategoryModel
            editCategory(editCategoryObj).then(() => {
              refetchNews()
              refetchCategory()
              onHide()
            })
          }}
        >
          {({handleSubmit}) => (
            <Form onSubmit={handleSubmit}>
              <Form.Group controlId='nameES' className='d-flex flex-column mb-8'>
                <Form.Label className='fs-4 text-black'>
                  {intl.formatMessage({id: 'CATEGORIES.MODAL_CATEGORIES.LABEL_NAME'})}{' '}
                  {intl.formatMessage({id: 'CATEGORIES.MODAL_CATEGORIES.LANGUAGE_ES'})}
                </Form.Label>
                <Field type='text' name='nameES' className='form-control bg-light border-0 text-black' maxLength='15' />
              </Form.Group>

              <Form.Group controlId='nameEN' className='d-flex flex-column mb-8'>
                <Form.Label className='fs-4 text-black'>
                  {intl.formatMessage({id: 'CATEGORIES.MODAL_CATEGORIES.LABEL_NAME'})}{' '}
                  {intl.formatMessage({id: 'CATEGORIES.MODAL_CATEGORIES.LANGUAGE_EN'})}
                </Form.Label>
                <Field type='text' name='nameEN' className='form-control bg-light border-0 text-black' maxLength='15' />
              </Form.Group>

              <Button variant='primary' type='submit' className='w-100 mt-8'>
                {intl.formatMessage({id: 'BUTTON.EDIT'})}
              </Button>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  )
}

export default EditCategoryModal
