export const useSaveFilters = () => {
  const saveFilter = (
    nameFilter: string,
    valueFilter: string | number,
    validForPathName?: string
  ) => {
    const currentPathNameForSave = validForPathName
      ? btoa(validForPathName as string)
      : btoa(currentPathName())
    const keySavedFilters = 'savedFilters_' + currentPathNameForSave
    const savedFilters = localStorage.getItem(keySavedFilters)
    if (savedFilters) {
      // update
      const oldFilters = JSON.parse(savedFilters)
      oldFilters[nameFilter] = valueFilter
      localStorage.setItem(keySavedFilters, JSON.stringify(oldFilters))
    } else {
      // create
      const newFilters = {}
      newFilters[nameFilter] = valueFilter
      localStorage.setItem(keySavedFilters, JSON.stringify(newFilters))
    }
  }
  const getSavedFilter = (nameFilter?: string) => {
    const keySavedFilters = 'savedFilters_' + btoa(currentPathName())
    const savedFilters = localStorage.getItem(keySavedFilters)
    if (savedFilters) {
      const oldFilters = JSON.parse(savedFilters)
      if (nameFilter) {
        return oldFilters[nameFilter]
      }
      return oldFilters
    }
    return {}
  }
  const existSavedFilter = (nameFilter: string) => {
    const savedFilter = getSavedFilter(nameFilter)
    if (typeof savedFilter === 'number') {
      // If the value saved has a value type number need less checks
      return savedFilter
    }
    return savedFilter && savedFilter.length > 0 ? true : false
  }
  const removeSavedFilter = (nameFilter: string) => {
    const keySavedFilters = 'savedFilters_' + btoa(currentPathName())
    const savedFilters = localStorage.getItem(keySavedFilters)
    if (savedFilters) {
      const oldFilters = JSON.parse(savedFilters)
      delete oldFilters[nameFilter]
      localStorage.setItem(keySavedFilters, JSON.stringify(oldFilters))
    }
  }
  const currentPathName = () => {
    const currPath = localStorage.getItem('currentPathName')
    if (currPath) {
      return currPath as string
    }
    return '/'
  }
  const checkSaveFilters = () => {
    if (currentPathName() === '/dashboard') {
      Object.entries(localStorage)
        .map((x) => x[0])
        .filter((x) => x.substring(0, 13) === 'savedFilters_')
        .map((x) => localStorage.removeItem(x))
    }
  }
  return {
    saveFilter,
    checkSaveFilters,
    getSavedFilter,
    existSavedFilter,
    removeSavedFilter,
    currentPathName,
  }
}
