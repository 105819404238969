import {Modal, Button, Form} from 'react-bootstrap'
import {Formik, Field} from 'formik'
import {useIntl} from 'react-intl'
import {addNewsModel} from '../../requests/requests'
import FileInput from '../../helpers/imgToBase64'
import {QuillEditor} from '../QuillEditor'
import {useState} from 'react'

function AddNewsModal({show, onHide, refetch}) {
  const intl = useIntl()
  const [editorValue, setEditorValue] = useState('')

  return (
    <Modal
      show={show}
      onHide={onHide}
      dialogClassName='modal-dialog modal-dialog-centered mw-900px'
    >
      <Modal.Header closeButton>
        <h2 className='text-black'>
          {intl.formatMessage({id: 'NEWS.MODAL_NEWS.ADD_NEWS_PAGE_TITLE'})}
        </h2>
      </Modal.Header>
      <Modal.Body className='modal-body py-lg-10 px-lg-20'>
        <Formik
          initialValues={{
            title: '',
            description: '',
            imageBs64: '',
            lang: 0,
            enable: false,
            externalLink: '',
          }}
          onSubmit={({title, imageBs64, lang, enable, externalLink}) => {
            lang = Number(lang)
            addNewsModel({
              title,
              description: editorValue,
              imageBs64,
              lang,
              enable,
              externalLink,
            }).then(() => {
              refetch()
              onHide()
              setEditorValue('')
            })
          }}
        >
          {({handleSubmit}) => (
            <Form onSubmit={handleSubmit}>
              <Form.Group controlId='title' className='d-flex flex-column mb-8'>
                <Form.Label className='fs-4 text-black'>
                  {intl.formatMessage({id: 'NEWS.MODAL_NEWS.LABEL_TITLE'})}
                </Form.Label>
                <Field
                  type='text'
                  name='title'
                  className='form-control bg-light border-0 text-black'
                />
                <span className='text-gray-400 mt-2'>
                  {intl.formatMessage({id: 'NEWS.MODAL_NEWS.TEXTLABEL_TITLE'})}
                </span>
              </Form.Group>
              <Form.Group controlId='description' className='d-flex flex-column mb-8'>
                <Form.Label className='fs-4 text-black'>
                  {intl.formatMessage({id: 'NEWS.MODAL_NEWS.LABEL_CONTENT'})}
                </Form.Label>
                <div className='bg-light border-0 text-black'>
                  <QuillEditor value={editorValue} setValue={setEditorValue} />
                </div>
                <span className='text-gray-400 mt-2'>
                  {intl.formatMessage({id: 'NEWS.MODAL_NEWS.TEXTLABEL_CONTENT'})}
                </span>
              </Form.Group>
              <Form.Group controlId='imageBs64' className='d-flex flex-column mb-8'>
                <Form.Label className='fs-4 text-black'>
                  {intl.formatMessage({id: 'NEWS.MODAL_NEWS.LABEL_IMAGE'})}
                </Form.Label>
                <FileInput />
                <span className='text-gray-400 mt-2'>
                  {intl.formatMessage({id: 'NEWS.MODAL_NEWS.TEXTLABEL_IMAGE'})}
                </span>
              </Form.Group>
              {/* dropdown idiomas */}
              <Form.Group controlId='lang' className='d-flex flex-column my-8'>
                <Form.Label className='fs-4 text-black'>
                  {intl.formatMessage({id: 'NEWS.MODAL_NEWS.LABEL_LANGUAGE'})}
                </Form.Label>
                <Field
                  as='select'
                  name='lang'
                  className='form-control max-w-200 position-relative bg-light border-0 text-black'
                >
                  <option value={0}>
                    {intl.formatMessage({id: 'NEWS.MODAL_NEWS.LANGUAGE_EN'})}
                  </option>
                  <option value={1}>
                    {intl.formatMessage({id: 'NEWS.MODAL_NEWS.LANGUAGE_ES'})}
                  </option>
                </Field>
                <span className='text-gray-400 mt-2'>
                  {intl.formatMessage({id: 'NEWS.MODAL_NEWS.TEXTLABEL_LANGUAGE'})}
                </span>
              </Form.Group>

              <Form.Group controlId='title' className='d-flex flex-column mb-8'>
                <Form.Label className='fs-4 text-black'>
                  {intl.formatMessage({id: 'NEWS.MODAL_NEWS.LABEL_EXTERNAL_LINK'})}
                </Form.Label>
                <Field
                  type='text'
                  name='externalLink'
                  className='form-control bg-light border-0 text-black'
                />
                <span className='text-gray-400 mt-2'>
                  {intl.formatMessage({id: 'NEWS.MODAL_NEWS.TEXTLABEL_EXTERNAL_LINK'})}
                </span>
              </Form.Group>

              <Form.Group className='d-flex align-items-center'>
                <Field
                  type='checkbox'
                  name='enable'
                  id='enableCheck'
                  className='d-flex justify-content-start'
                  style={{accentColor: '#071426'}}
                />

                <Form.Label className='mb-0 mx-2 fs-4 text-black w-100' htmlFor='enableCheck'>
                  {intl.formatMessage({id: 'NEWS.MODAL_NEWS.LABEL_ENABLED'})}
                </Form.Label>
              </Form.Group>
              <span className='text-gray-400 mt-2'>
                {intl.formatMessage({id: 'NEWS.MODAL_NEWS.TEXTLABEL_ENABLE'})}
              </span>
              <div className='d-flex justify-content-end'>
                <Button
                  type='reset'
                  className='btn btn-secondary btm-sm mt-8  me-4'
                  onClick={onHide}
                >
                  {intl.formatMessage({id: 'BUTTON.CANCEL'})}
                </Button>
                <Button variant='primary' type='submit' className='btn btn-primary btm-sm mt-8'>
                  {intl.formatMessage({id: 'BUTTON.SAVE'})}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  )
}

export default AddNewsModal
