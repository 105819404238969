import {useIntl} from 'react-intl'
import {Field} from 'formik'
import {Button, Form, Spinner} from 'react-bootstrap'
import {useState} from 'react'
import {useAdminShoppingCart} from '../context/AdminShoppingCartContext'
import {Category, Product} from '../models/models'
import {productsRequirePreferences} from '../constants'
import {OrderPreferences} from './finish-order/orderPreferences'
import {
  OrderQuestionsModel,
  createOrderModalQuestions,
} from '../modules/wizards/components/CreateOrder'
import {getProductFormat} from '../hooks/getProductFormat'
import {ToastifyCaller, ToastifyType} from './Toastify'

export const ProductItemAdmin = ({product, productsSameType}) => {
  const intl = useIntl()
  const [showOrderPreferences, setShowOrderPreferences] = useState<undefined | Product>(undefined)
  const {cartItems, setCartItems, isAddingToCart, setIsAddingToCart} = useAdminShoppingCart()
  const [stockRatioInput, setStockRatioInput] = useState(
    productsSameType.length > 0 ? productsSameType[0].stockRatio : product.stockRatio
  )
  const [quantity, setQuantity] = useState(1)
  const [packageUnitInput, setPackageUnitInput] = useState(
    productsSameType.length > 0 ? productsSameType[0].packageUnit : product.packageUnit
  )
  const handleSubmitPreferences = async (values: OrderQuestionsModel) => {
    const isValid = await createOrderModalQuestions.isValid(values)
    if (isValid) {
      addToCart(showOrderPreferences as Product, values.completeOrder, values.splitPieces)
    }
    setShowOrderPreferences(undefined) // reset
  }
  const handleAddToCart = (Product: Product) => {
    setIsAddingToCart(Product.code)
    setShowOrderPreferences(undefined) // reset
    const alreadyHasInCart = cartItems.find(
      (x) =>
        x.code === Product.code &&
        x.stockRatio === Product.stockRatio &&
        x.packageUnit === Product.packageUnit
    )
    if (productsRequirePreferences.includes(Product.code) && !alreadyHasInCart) {
      setShowOrderPreferences(Product)
    } else {
      addToCart(Product)
    }
  }
  function validateStockRatio(input: any) {
    const inputValue = input.value
    let valid = false
    if (inputValue === '' || isNaN(inputValue)) {
      valid = false
    } else {
      const maxValue = parseInt(input.getAttribute('max'))
      const minValue = parseInt(input.getAttribute('min'))
      const currentValue = parseInt(inputValue)
      if (currentValue > maxValue) {
        setStockRatioInput(maxValue)
        ToastifyCaller(
          intl.formatMessage({id: 'GENERAL_ITEM_KG_MORE_THAN_ALLOWED'}, {ITEM_KG: maxValue}),
          ToastifyType.INFO
        )
        valid = false
      } else {
        if (currentValue < minValue) {
          setStockRatioInput(minValue)
          ToastifyCaller(
            intl.formatMessage({id: 'GENERAL_ITEM_KG_LESS_THAN_ALLOWED'}, {ITEM_KG: minValue}),
            ToastifyType.INFO
          )
          valid = false
        } else {
          valid = true
        }
      }
    }
    return valid
  }
  // update value only if quantity is different from 0 and the value introduced in input is a number
  function handleStockRatioChange(e: any) {
    const inputValue = e.target.value

    if (inputValue === '' || isNaN(inputValue)) {
      setStockRatioInput(0)
    } else {
      setStockRatioInput(parseInt(inputValue))

      if (e.target.options) {
        const selectedOpt = e.target.options[e.target.selectedIndex]
        const packageUnitOpt = selectedOpt.getAttribute('data-packageunit')
        const codeOpt = selectedOpt.getAttribute('data-code')
        const stockRatioOpt = parseInt(selectedOpt.getAttribute('data-stockRatio')) as number
        const productSelected = productsSameType.find(
          (x) =>
            x.code === codeOpt && x.stockRatio === stockRatioOpt && x.packageUnit === packageUnitOpt
        ) as Product
        setPackageUnitInput(productSelected?.packageUnit)
      } else {
        setPackageUnitInput('')
      }
    }
  }
  function handleQuantityChange(e: any) {
    const inputValue = e.target.value
    if (inputValue === '' || isNaN(inputValue)) {
      setQuantity(1)
    } else {
      setQuantity(parseInt(inputValue))
    }
  }
  function addToCart(
    Product: Product,
    CompleteOrder: boolean = false,
    SplitPieceQuestion: boolean = false
  ) {
    //console.log("[INFO] Add to cart. Code[" + Product.code + "] Quantity["+quantity+"] [StockRatio: " + stockRatioInput + "] PackageUnit[" + packageUnitInput + "]");
    //check if product already exists in cart

    const productExists = cartItems.find(
      (item) => item.code === Product.code && item.stockRatio === stockRatioInput
    )
    const reallyExistsProduct =
      product.stockRatio === Product.stockRatio ||
      productsSameType.find(
        (item) => item.code === Product.code && item.stockRatio === stockRatioInput
      ) != null
    if (reallyExistsProduct) {
      if (productExists) {
        setCartItems(
          cartItems.map((item) => {
            if (item.code === Product.code && item.stockRatio === stockRatioInput) {
              const newQuantity = (item.quantity += quantity)
              if (packageUnitInput && packageUnitInput.length > 0) {
                return {
                  ...item,
                  quantity: newQuantity,
                  stockRatio: stockRatioInput,
                  packageUnit: packageUnitInput,
                  netPricePerUnit: Product.netPricePerUnit,
                }
              } else {
                return {
                  ...item,
                  quantity: newQuantity,
                  stockRatio: stockRatioInput,
                  netPricePerUnit: Product.netPricePerUnit,
                }
              }
            } else {
              return item
            }
          })
        )
      } else {
        // if product doesn't exist, add it to cart
        if (packageUnitInput && packageUnitInput.length > 0) {
          setCartItems([
            ...cartItems,
            {
              code: Product.code,
              stockRatio: stockRatioInput,
              quantity: quantity,
              packageUnit: packageUnitInput,
              netPricePerUnit: Product.netPricePerUnit,
              splitPieceQuestion: SplitPieceQuestion,
              completeOrder: CompleteOrder,
            },
          ])
        } else {
          setCartItems([
            ...cartItems,
            {
              code: Product.code,
              stockRatio: stockRatioInput,
              quantity: quantity,
              packageUnit: Product.packageUnit,
              netPricePerUnit: Product.netPricePerUnit,
              splitPieceQuestion: SplitPieceQuestion,
              completeOrder: CompleteOrder,
            },
          ])
        }
      }
    }
    setIsAddingToCart('')
  }
  function inCart() {
    const inCartItems = cartItems.filter((x) => x.code === product.code)
    return inCartItems
  }
  return (
    <article
      key={`${product.code}-${product.stockRatio}`}
      className='row align-items-center justify-content-between py-6 px-4 rounded g-0 mb-1'
      // conditional render where if product have a promotion, it has a different style
      style={product.inPromotion ? {border: '1px dashed #102038'} : {border: '1px dotted #f1f1f2'}}
    >
      <div className='d-flex align-items-center gap-4 col-12 col-lg-5'>
        <img
          src={product.urlImage}
          className='rounded'
          height={50}
          width={50}
          alt={product.description}
          style={{objectFit: 'cover'}}
        />
        <div className='d-flex flex-column justify-content-center'>
          <h3 className='fs-6 mb-0'>{product.description}</h3>
          <div className='d-flex gap-4'>
            <span style={{color: '#99A1B7'}}>{product.code}</span>
          </div>
          <div className='d-flex gap-4'>
            {inCart().length > 0 ? (
              <span className='badge badge-success mt-2'>
                {intl.formatMessage({id: 'CART.ITEM_ADDED'})}
              </span>
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
      <div className='col-12 col-lg-2'>
        <div className='d-flex gap-2 align-items-center flex-wrap'>
          {/* badge for promotion article */}
          {product.inPromotion && (
            <span className='badge badge-primary'>
              {product.promotionalText && product.promotionalText.length > 0
                ? product.promotionalText
                : intl.formatMessage({id: 'CART.ITEM_PROMOTION'})}
            </span>
          )}
          {product.categories.map((el: Category, ind) => {
            return (
              <span className='badge badge-secondary text-primary' key={`category-${ind}`}>
                {el.name}
              </span>
            )
          })}
        </div>
      </div>
      <div className='d-flex justify-content-start align-items-center col-6 col-lg-2'>
        {(product.stockUnit === product.salesUnit && product.packageUnit === 'PZA' && (
          <div className='d-flex flex-column justify-content-center align-items-start gap-2'>
            <span className='text-muted fw-semibold'>
              {intl.formatMessage({id: 'CART.ITEM_PACKAGE_FORMAT'}).toUpperCase()}:
            </span>
            <div className='d-flex gap-4'>
              <Field
                className='p-3 text-center fs-3'
                type='number'
                required
                style={{
                  width: '80px',
                  height: '40px',
                  border: '1px solid #dbdfe9',
                  borderRadius: '6px',
                }}
                onChange={handleStockRatioChange}
                value={stockRatioInput}
                min={product.zMin}
                max={product.zMax}
                name='stockRatio'
                pattern='[0-9]*n'
              ></Field>
              <span className='d-flex justify-content-center align-items-center'>
                {intl.formatMessage({id: 'CART.ITEM_FORMAT_KG_PIECE'}).toUpperCase()}
              </span>
            </div>
          </div>
        )) || (
          <>
            <div className='d-flex flex-column justify-content-center align-items-start gap-2'>
              <span className='text-muted fw-semibold'>
                {intl.formatMessage({id: 'CART.ITEM_PACKAGE_FORMAT'}).toUpperCase()}:
              </span>
              {productsSameType.length <= 1 && (
                <div>
                  <p
                    className='d-flex align-items-center justify-content-center m-0 fs-5'
                    style={{height: '40px'}}
                  >
                    {getProductFormat(product.packageUnit, product.stockRatio, '', product.code)}
                  </p>
                </div>
              )}
              {productsSameType.length > 1 && (
                <>
                  <div className='d-flex align-items-center justify-content-between'>
                    <Form.Select
                      aria-label={intl.formatMessage({id: 'CART.ITEM_SELECT_FORMAT'}).toUpperCase()}
                      onChange={handleStockRatioChange}
                      style={{height: '40px'}}
                    >
                      {productsSameType.map((x) => {
                        return (
                          <option
                            key={x.stockRatio}
                            data-stockratio={x.stockRatio}
                            data-packageunit={x.packageUnit}
                            data-code={x.code}
                          >
                            {x.stockRatio}
                          </option>
                        )
                      })}
                    </Form.Select>
                    <div className='w-100 ms-3'>/ UN</div>
                  </div>
                </>
              )}
            </div>
          </>
        )}
      </div>
      <div className='d-flex gap-4 col-6 col-lg-2 justify-content-start align-items-center'>
        {
          <>
            <div className='d-flex flex-column justify-content-center align-items-start gap-2'>
              <span className='text-muted fw-semibold'>
                {intl.formatMessage({id: 'CART.ITEM_QUANTITY'}).toUpperCase()}:
              </span>
              <Field
                className='p-3 text-center fs-3'
                type='number'
                required
                style={{
                  width: '80px',
                  height: '40px',
                  border: '1px solid #dbdfe9',
                  borderRadius: '6px',
                }}
                onChange={handleQuantityChange}
                value={quantity}
                name='quantity'
                pattern='[0-9]*n'
              ></Field>
            </div>
          </>
        }
      </div>
      <div className='d-flex gap-4 col-6 col-lg-1 justify-content-end align-items-center'>
        {/* Add to cart */}
        <Button
          className={`btn btn-primary h-50 px-4 py-2 w-100 w-lg-auto d-flex align-items-center ${
            isAddingToCart.length > 0 ? 'disabled' : ''
          }`}
          onClick={(e) => {
            const input = e.target as HTMLButtonElement
            if (input) {
              const htmlEl = input.closest('article') as HTMLElement
              if (htmlEl) {
                const inputElement = input
                  .closest('article')!
                  .querySelector('input[name="stockRatio"]')
                if (inputElement) {
                  const canAddToCart = validateStockRatio(inputElement)
                  if (canAddToCart) {
                    handleAddToCart(product)
                  }
                } else {
                  handleAddToCart(product)
                }
              }
            }
          }}
        >
          {intl.formatMessage({id: 'BUTTON.ADD'})}
          {isAddingToCart.length > 0 && isAddingToCart === product.code && (
            <Spinner animation='border' className='mx-3'></Spinner>
          )}
        </Button>
      </div>
      <OrderPreferences
        show={showOrderPreferences}
        onHide={() => {
          setIsAddingToCart(false)
          setShowOrderPreferences(undefined)
        }}
        onSubmit={handleSubmitPreferences}
      ></OrderPreferences>
    </article>
  )
}
