import { FC, useState } from 'react'
import { useIntl } from 'react-intl'
import { Link, useParams } from 'react-router-dom'
import { MyOrder, MyOrderLine, OrderDocument } from '../../models/myOrders'
import { requestMyOrderDetail, requestOrderDetail } from '../../requests/requests'
import { useQuery, useQueryClient } from 'react-query'
import { formatPrice } from '../../helpers/formatPrice'
import { useAuth } from '../../modules/auth'
import { getProductFormat } from '../../hooks/getProductFormat'
import { OrderStatus } from '../../models/models'
import { Button, Col, Row, Spinner } from 'react-bootstrap'
import { useGoBack } from '../../hooks/useGoBack'
import { generateInvoicePDF, generatePackagingListPDF } from '../../helpers/utils'
import { useBtnTitles } from '../../hooks/useBtnTitles'
import { KTIcon } from '../../../_metronic/helpers'

const MyOrderDetail: FC = () => {
  const intl = useIntl()
  const { auth } = useAuth()
  const { orderId } = useParams()
  const queryClient = useQueryClient()
  const { goBackFn, goBackHistoryFn, hasAnyHistory } = useGoBack()
  const btnTitles = useBtnTitles()
  const [generatingPDF, setGeneratingPDF] = useState<number>(0) // Type PDF 1 or 2 (1: Invoice, 2: PackagingList)
  const [generatingOrderPDF, setGeneratingOrderPDF] = useState<string>('') // Associed order id for the request of generating PDF
  const { isFetching } = useQuery<MyOrder>(
    [`orderEdit${orderId}`],
    async () => {
      if (auth?.isAdmin) {
        const { data: x } = await requestOrderDetail(orderId as string)
        x.lines.forEach((p: MyOrderLine) => {
          if (p.urlImage == null) {
            p.urlImage =
              'https://img.freepik.com/vector-premium/vector-icono-imagen-predeterminado-pagina-imagen-faltante-diseno-sitio-web-o-aplicacion-movil-no-hay-foto-disponible_87543-11093.jpg'
          }
        })
        return x
      } else {
        const { data: x } = await requestMyOrderDetail(orderId as string)
        x.lines.forEach((p: MyOrderLine) => {
          if (p.urlImage == null) {
            p.urlImage =
              'https://img.freepik.com/vector-premium/vector-icono-imagen-predeterminado-pagina-imagen-faltante-diseno-sitio-web-o-aplicacion-movil-no-hay-foto-disponible_87543-11093.jpg'
          }
        })
        return x
      }
    },
    { staleTime: 60 * 60 * 24 * 365, enabled: !!orderId }
  )
  const myOrder: MyOrder = queryClient.getQueryData([`orderEdit${orderId}`]) as MyOrder

  const invoiceList = () => {
    let orderDocs: OrderDocument[] = []
    if (myOrder && myOrder.lines) {
      myOrder.lines.forEach((line) => {
        line.invoices.forEach((invoiceId) => {
          const invoiceDocs = orderDocs.find((x) => x.invoiceId === invoiceId)
          if (!invoiceDocs) {
            orderDocs.push({
              invoiceId: invoiceId,
              deliveryNotes: line.deliveryNotes,
            } as OrderDocument)
          } else {
            if (!line.deliveryNotes) {
              line.deliveryNotes = []
            }
            line.deliveryNotes.forEach((deliveryNoteId) => {
              if (!invoiceDocs.deliveryNotes.includes(deliveryNoteId)) {
                invoiceDocs.deliveryNotes.push(deliveryNoteId)
              }
            })
          }
        })
        // TODO LISTA DE DELIVERY tener en cuenta xk vendrian venir sin factura
        // line.deliveryNotes.forEach((deliveryNoteId) => {
        //   const invoiceDocs = orderDocs.find((x) => x.invoiceId === invoiceId)
        //   if (!invoiceDocs) {
        //     orderDocs.push({
        //       invoiceId: invoiceId,
        //       deliveryNotes: line.deliveryNotes,
        //     } as OrderDocument)
        //   } else {
        //     if (!line.deliveryNotes) {
        //       line.deliveryNotes = []
        //     }
        //     line.deliveryNotes.forEach((deliveryNoteId) => {
        //       if (!invoiceDocs.deliveryNotes.includes(deliveryNoteId)) {
        //         invoiceDocs.deliveryNotes.push(deliveryNoteId)
        //       }
        //     })
        //   }
        // })
      })
    }
    return orderDocs
  }

  return (
    <div className='app-container container-fluid'>
      {/* Wrapper */}
      <div className='d-flex flex-column gap-7 gap-lg-10'>
        <div>
          <h1>{intl.formatMessage({ id: 'MY_ORDER_DETAILS_TITLE' })}</h1>
        </div>
        <div className='d-flex flex-column flex-xl-row gap-7 gap-lg-10'>
          {myOrder && (
            <>
              {/* Client card */}
              <article className='card card-flush py-4 flex-row-fluid position-relative'>
                <div className='position-absolute top-0 end-0 bottom-0 opacity-10 d-flex align-items-center me-5'>
                  <i className='ki-solid ki-two-credit-cart' style={{ fontSize: '14em' }}></i>{' '}
                </div>
                <div className='card-header'>
                  <div className='card-title'>
                    <h3 className='fs-2'>
                      {intl.formatMessage({ id: 'ORDERS.CREATE_NEW_ORDER_DETAILS_CUSTOMER_DATA' })}
                    </h3>
                  </div>
                </div>
                <div className='card-body pt-0'>
                  <span className='fs-4 mb-0'>{myOrder?.detail.clientBusinessName}</span>
                  <br />{' '}
                </div>
              </article>

              {/* Adress card */}
              <article className='card card-flush py-4 flex-row-fluid position-relative'>
                <div className='position-absolute top-0 end-0 bottom-0 opacity-10 d-flex align-items-center me-5'>
                  <i className='ki-solid ki-delivery' style={{ fontSize: '14em' }}></i>
                </div>
                <div className='card-header'>
                  <div className='card-title'>
                    <h3 className='fs-2'>
                      {intl.formatMessage({ id: 'ORDERS.CREATE_NEW_ORDER_DETAILS_SHIPPING_ADDRESS' })}
                    </h3>
                  </div>
                </div>
                <div className='card-body pt-0'>
                  {myOrder?.detail.shippingAddressDescription} <br />(
                  {myOrder?.detail.shippingAddressSageCode}) <br />
                  {myOrder?.detail.shippingAddress} <br />
                  {myOrder?.detail.clientPostalCode} - {myOrder?.detail.shippingAddressCity} <br />
                  {myOrder?.detail.shippingAddressCountryName} <br />
                  <br />{' '}
                </div>
              </article>

              {/* Date card */}
              <article className='card card-flush py-4 flex-row-fluid position-relative'>
                <div className='position-absolute top-0 end-0 bottom-0 opacity-10 d-flex align-items-center me-5'>
                  <i className='ki-solid ki-calendar' style={{ fontSize: '14em' }}></i>
                </div>
                <div className='card-header'>
                  <div className='card-title'>
                    <h3 className='fs-2'>
                      {intl.formatMessage({ id: 'ORDERS.CREATE_NEW_ORDER_DETAILS_SHIPPING_DATE' })}
                    </h3>
                  </div>
                </div>
                <div className='card-body pt-0'>
                  {new Date(myOrder.expeditionDate).toLocaleDateString('en-GB')} <br />
                  <br />
                </div>
              </article>
            </>
          )}
        </div>

        {isFetching && <Spinner animation='border'></Spinner>}

        <div className='card card-flush py-4 flex-row-fluid overflow-hidden'>
          <div className='card-body pt-0 position-absolute' style={{ right: 0 }}>
            <Row>
              {myOrder && myOrder.status === OrderStatus.Pending && (
                <Col xs='12' lg='12' className='text-end'>
                  <span className={`badge badge-light-warning`}>
                    <span>
                      {intl.formatMessage({
                        id: 'ORDER.STATUS.' + OrderStatus[myOrder.status].toUpperCase(),
                      })}
                    </span>
                  </span>
                </Col>
              )}
              {myOrder && myOrder.status === OrderStatus.Validated && myOrder.sageOrderStatus && (
                <Col xs='12' lg='12' className='text-end mt-3'>
                  <span
                    className={`badge ${myOrder.lines &&
                        myOrder.lines.length > 0 &&
                        myOrder.lines.find((x) => x.invoices.length > 0)
                        ? 'badge-light-success'
                        : 'badge-light-primary'
                      }`}
                  >
                    <span>{myOrder.sageOrderStatus}</span>
                  </span>
                </Col>
              )}
            </Row>
          </div>
          <div className='card-header'>
            <div className='card-title d-block'>
              <h3 className='fs-2'>{intl.formatMessage({ id: 'MY_ORDER_DETAILS.INVOICE_TITLE' })}</h3>
              <div className='text-gray-400 pt-1 fw-semibold fs-6'>
                {myOrder && myOrder.sageOrder ? '#' + myOrder.sageOrder : ''}
              </div>
              <div className='text-gray-400 pt-1 fw-semibold fs-6'>
                <div className='my-3'>
                  <h6>{intl.formatMessage({ id: 'ORDER.ATTACHMENTS' })}</h6>
                </div>
                <Row className='justify-content-start'>
                  {invoiceList().map((x) => {
                    return (
                      <>
                        <Col
                          xs='auto'
                          lg={`${invoiceList().length > 1 ? '4' : '6'}`}
                          className='border border-1 mx-3'
                          key={x.invoiceId}
                        >
                          <Row className='p-3'>
                            <Col xs='12'>
                              <div className='text-center'>#{x.invoiceId}</div>
                            </Col>
                            <Col xs='12'>
                              <Row className='align-items-center justify-content-between'>
                                <Col>
                                  <a
                                    className={`btn btn-icon btn-bg-light btn-active-color-primary btn-sm position-relative my-2 w-100 me-3 p-5 ${generatingPDF ? 'disabled' : ''
                                      }`}
                                    title={btnTitles.invoice}
                                    href='#invoice'
                                    onClick={async (e) => {
                                      e.preventDefault()
                                      await generateInvoicePDF(
                                        setGeneratingPDF,
                                        setGeneratingOrderPDF,
                                        myOrder,
                                        x.invoiceId
                                      )
                                    }}
                                  >
                                    {btnTitles.invoice}&nbsp;
                                    {generatingPDF === 1 && generatingOrderPDF === x.invoiceId && (
                                      <Spinner
                                        className='position-absolute'
                                        animation='border'
                                      ></Spinner>
                                    )}
                                    <KTIcon iconName='document' className='fs-3' />
                                  </a>
                                </Col>
                                <Col>
                                  <a
                                    className={`btn btn-icon btn-bg-light btn-active-color-primary btn-sm position-relative my-2 w-100 p-5 ${generatingPDF ? 'disabled' : ''
                                      }`}
                                    title={btnTitles.packagingList}
                                    href='#packaging-list'
                                    onClick={async (e) => {
                                      e.preventDefault()
                                      await generatePackagingListPDF(
                                        setGeneratingPDF,
                                        setGeneratingOrderPDF,
                                        myOrder,
                                        x.invoiceId
                                      )
                                    }}
                                  >
                                    {btnTitles.packagingList}&nbsp;
                                    {generatingPDF === 2 && generatingOrderPDF === x.invoiceId && (
                                      <Spinner
                                        className='position-absolute'
                                        animation='border'
                                      ></Spinner>
                                    )}
                                    <KTIcon iconName='questionnaire-tablet' className='fs-3' />
                                  </a>
                                </Col>
                              </Row>
                              <ul className='list-style-none p-0'>
                                {x.deliveryNotes.map((note, index) => (
                                  <li className='text-center' key={note + '-' + index}>
                                    {note}
                                  </li>
                                ))}
                              </ul>
                            </Col>
                          </Row>
                        </Col>
                      </>
                    )
                  })}
                </Row>
                {myOrder && myOrder.detail.customerExternalReference && <div className='my-3'>
                  <h6>{intl.formatMessage({ id: 'ORDER.EXTERNAL_REF' })}: {myOrder && myOrder.detail.customerExternalReference ? myOrder.detail.customerExternalReference : '-'}</h6>
                </div>}
              </div>
            </div>
          </div>
        </div>

        {!isFetching && (
          <>
            {/* Begin Product list */}
            <div className='card card-flush py-4 flex-row-fluid overflow-hidden'>
              {/* begin::Card header*/}
              <div className='card-header'>
                <div className='card-title w-100'>
                  <Row className='w-100'>
                    <Col xs='6' lg='6'>
                      <h2>{intl.formatMessage({ id: 'MY_ORDER_DETAILS_TITLE' })}</h2>
                    </Col>
                  </Row>
                </div>
              </div>
              {/* end::Card header*/}
              {/* begin::Card body*/}
              <div className='card-body pt-0'>
                <div className='table-responsive'>
                  {/* begin::Table*/}
                  <table className='table align-middle table-row-dashed fs-6 gy-5 mb-0'>
                    <thead>
                      <tr className='text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0'>
                        <th className='min-w-175px'>
                          {intl.formatMessage({ id: 'MY_ORDER_DETAILS.TABLE_HEADER_PRODUCT' })}
                        </th>
                        <th className='min-w-70px text-end'>
                          {intl.formatMessage({ id: 'MY_ORDER_DETAILS.TABLE_HEADER_FORMAT' })}
                        </th>
                        <th className='min-w-70px text-end'>
                          {intl.formatMessage({ id: 'MY_ORDER_DETAILS.TABLE_HEADER_UNITS' })}
                        </th>
                        <th className='min-w-100px text-end'>
                          {intl.formatMessage({ id: 'MY_ORDER_DETAILS.TABLE_HEADER_UNIT_PRICE' })}
                        </th>
                        <th className='min-w-100px text-end'>
                          {intl.formatMessage({ id: 'MY_ORDER_DETAILS.TABLE_HEADER_DISCOUNT' })}
                        </th>
                        <th className='min-w-100px text-end'>
                          {intl.formatMessage({ id: 'MY_ORDER_DETAILS.TABLE_HEADER_TOTAL' })}
                        </th>
                      </tr>
                    </thead>
                    <tbody className='fw-semibold text-gray-600'>
                      {myOrder &&
                        myOrder?.lines &&
                        myOrder?.lines.map((product) => (
                          <tr key={`${product.code}${product.stockRatio}`}>
                            <td>
                              <div className='d-flex align-items-center'>
                                {/* begin::Thumbnail*/}
                                <img
                                  src={product.urlImage}
                                  className='rounded'
                                  alt={product.description}
                                  style={{ width: '50px', height: '50px' }}
                                />
                                {/* end::Thumbnail*/}
                                {/* begin::Title*/}
                                <div className='ms-5'>
                                  <div className='fw-bold text-primary'>{product.description}</div>
                                  <div className='fs-7 text-muted'>{product.code}</div>
                                </div>
                                {/* end::Title*/}
                              </div>
                            </td>
                            <td className='text-end'>
                              {getProductFormat(
                                product.packageUnit,
                                product.stockRatio,
                                intl.formatMessage({ id: 'GENERAL.OF' }),
                                product.code
                              )}
                            </td>
                            <td className='text-end'>{product.quantity}</td>
                            <td className='text-end'>
                              {product.netPricePerUnit !== product.grossPricePerUnit && (
                                <>
                                  {formatPrice(product.netPricePerUnit) + ' '}
                                  <span className='text-decoration-line-through'>
                                    ({formatPrice(product.grossPricePerUnit)})
                                  </span>
                                </>
                              )}
                              {product.netPricePerUnit === product.grossPricePerUnit && (
                                <>{formatPrice(product.netPricePerUnit)}</>
                              )}{' '}
                              / {product.packageUnit.startsWith('c') ? 'UN' : 'KG'}
                            </td>
                            <td className='text-end'>
                              {product.discountValue1 <= 0 && <div>-</div>}
                              {product.discountValue1 > 0 && product.discountValue2 <= 0 && (
                                <div>{product.discountValue1}%</div>
                              )}
                              {product.discountValue1 > 0 && product.discountValue2 > 0 && (
                                <div>
                                  {product.discountValue1}% / {product.discountValue2}%
                                </div>
                              )}
                            </td>
                            <td className='text-end'>{formatPrice(product.netPrice)}</td>
                          </tr>
                        ))}

                      {myOrder && (
                        <>
                          <tr>
                            <td colSpan={5} className='text-end'>
                              {intl.formatMessage({ id: 'MY_ORDER_DETAILS.TABLE_HEADER_PRICE' })}
                            </td>
                            <td className='text-end'>
                              {formatPrice(
                                myOrder?.lines.reduce(
                                  (acc, currentValue) => acc + currentValue.netPrice,
                                  0
                                )
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td colSpan={5} className='text-end'>
                              {intl.formatMessage({ id: 'MY_ORDER_DETAILS.TABLE_HEADER_DISCOUNT' })}
                            </td>
                            <td className='text-end'>{myOrder.detail.comercialDiscount} %</td>
                          </tr>
                          <tr>
                            <td colSpan={5} className='fs-3 text-dark text-end'>
                              {intl.formatMessage({ id: 'MY_ORDER_DETAILS.TABLE_HEADER_TOTAL' })}
                            </td>
                            <td className='text-dark fs-3 fw-bolder text-end'>
                              {formatPrice(myOrder.finalAmount)}
                            </td>
                          </tr>
                        </>
                      )}
                    </tbody>
                  </table>
                  {/* end::Table*/}
                </div>
              </div>
              {/* end::Card body*/}
            </div>
          </>
        )}

        <Row className='justify-content-between align-items-center'>
          <Col className='text-start'>
            {hasAnyHistory && (
              <a href='#goback' onClick={goBackHistoryFn}>
                <Button variant='secondary' type='button'>
                  {intl.formatMessage({ id: 'ORDERS.CREATE_NEW_ORDER_BUTTON_BACK' })}
                </Button>
              </a>
            )}
            {!hasAnyHistory && (
              <Link to={goBackFn()}>
                <Button variant='secondary' type='button'>
                  {intl.formatMessage({ id: 'ORDERS.CREATE_NEW_ORDER_BUTTON_BACK' })}
                </Button>
              </Link>
            )}
          </Col>
        </Row>
      </div>
    </div>
  )
}
export { MyOrderDetail }
