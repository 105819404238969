/* eslint-disable react-refresh/only-export-components */
import {FC, createContext, useContext} from 'react'
import {WithChildren} from '../helpers'
import { useAuth } from '../../app/modules/auth'

const I18N_CONFIG_KEY = import.meta.env.VITE_APP_I18N_CONFIG_KEY || 'i18nConfig'

type Props = {
  selectedLang: 'de' | 'en' | 'es' | 'fr'
}
const initialState: Props = {
  selectedLang: 'en',
}

function useGetLangConfig(): Props {
  const {auth} = useAuth()
  // Check first if have language from auth
  if (auth) {
    return {selectedLang: auth.language} as Props
  }
  // If not have language from auth (not logged) get language from browser
  const userLang = navigator.language
  if (userLang) {
    return {selectedLang: userLang.includes('es') ? 'es' : 'en'} as Props
  }
  // If not found yet the language get from localstorage or set default 'en'
  const ls = localStorage.getItem(I18N_CONFIG_KEY)
  if (ls) {
    try {
      return JSON.parse(ls) as Props
    } catch (er) {
      console.error(er)
    }
  }
  return initialState
}

// Side effect
export function setLanguage(lang: string) {
  setTimeout(() => {
    localStorage.setItem(I18N_CONFIG_KEY, JSON.stringify({selectedLang: lang}))
  }, 100)
}

const I18nContext = createContext<Props>(initialState)

const useLang = () => {
  return useContext(I18nContext).selectedLang
}

const MetronicI18nProvider: FC<WithChildren> = ({children}) => {
  const lang = useGetLangConfig()
  return <I18nContext.Provider value={lang}>{children}</I18nContext.Provider>
}

export {MetronicI18nProvider, useLang}
