import {useIntl} from 'react-intl'

export const useBtnTitles = () => {
  const intl = useIntl()

  return {
    delete: intl.formatMessage({id: 'BUTTON.DELETE'}),
    confirm: intl.formatMessage({id: 'BUTTON.CONFIRM'}),
    cancel: intl.formatMessage({id: 'BUTTON.CANCEL'}),
    save: intl.formatMessage({id: 'BUTTON.SAVE'}),
    edit: intl.formatMessage({id: 'BUTTON.EDIT'}),
    details: intl.formatMessage({id: 'BUTTON.DETAILS'}),
    invoice: intl.formatMessage({id: 'BUTTON.INVOICE'}),
    packagingList: intl.formatMessage({id: 'BUTTON.PACKAGING_LIST'}),
  }
}
