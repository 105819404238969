import {useIntl} from 'react-intl'
import {useShoppingCart} from '../../../context/ShoppingCartContext'
import {Button, Col, Row} from 'react-bootstrap'
import {Product} from '../../../models/models'
import {getProductFormat} from '../../../hooks/getProductFormat'
import { useProductsAvailablesForSelectedClient } from '../../../hooks/useProductsAvailablesForSelectedClient'
import { useSelectedExpeditionDate } from '../../../hooks/useSelectedExpeditionDate'

export default function InlineShoppingCart() {
  const intl = useIntl()
  //const queryClient = useQueryClient()
  const {cartItems, clearCart, removeItem} = useShoppingCart()
  //const selectedClient: Client = queryClient.getQueryData(['selectedUser']) as Client
  const selectedExpeditionDate = useSelectedExpeditionDate()
  const {availableProducts} = useProductsAvailablesForSelectedClient(selectedExpeditionDate)
  /*const availableProducts: Product[] = JSON.parse(
    selectedClient ? (localStorage.getItem('productsForSelectedClient' + selectedClient.id) as string) : '[]'
  )*/
  const productInfo = (code: string): Product => {
    const products: Product[] =
      availableProducts && availableProducts?.filter((product) => product.code === code)
    return availableProducts && products[0]
  }

  return (
    // PRODUCTS ADDED TO SHOPPING CART
    // This section will only show up if there is, at least, one product in the cart
    cartItems.length > 0 ? (
      <section className='d-flex flex-column align-content-start'>
        <div className='d-flex justify-content-between align-items-center mb-4'>
          <h3 className='fs-5 fw-medium'>{intl.formatMessage({id: 'ORDERS.CART_PRODUCTS'})}</h3>
          <Button className='px-4 py-2 fs-8' onClick={() => clearCart()}>
            {intl.formatMessage({id: 'ORDERS.CREATE_NEW_ORDER_SHOPPING_CART_CLEAR'})}
          </Button>
        </div>
        <ul
          className='d-flex flex-column w-100 list-unstyled gap-1'
          style={{overflowY: 'scroll', maxHeight: '20rem'}}
        >
          {cartItems
            ? cartItems.map((item) => {
                if (!productInfo(item.code)) return <></>
                return (
                  <li
                    key={`${item.code}-${item.stockRatio}`}
                    className='d-flex align-items-center justify-content-between'
                  >
                    <article
                      className='d-flex align-items-center justify-content-between gap-4 w-100 py-6 px-4 rounded'
                      style={{border: '1px dashed #f1f1f2'}}
                      key={`${item.code} - ${item.stockRatio}`}
                    >
                      <Row className='w-100 justify-content-between align-items-center'>
                        <Col lg='6'>
                          <div className='d-flex align-items-center gap-4'>
                            <img
                              className='rounded'
                              src={productInfo(item.code).urlImage}
                              alt={item.code}
                              width={50}
                              height={50}
                            />
                            <div className='d-flex flex-column justify-content-center gap-2'>
                              <h3 className='fs-5 m-0'>{productInfo(item.code).description}</h3>
                              <span className='text-black-50'>{item.code}</span>
                              {/* <div>
                              {item.splitPieceQuestion && (
                                <span className='badge badge-light-primary me-3'>
                                  {item.splitPieceQuestion ? 'Cortar' : ''}
                                </span>
                              )}
                              {item.completeOrder && (
                                <span className='badge badge-light-primary'>
                                  {item.splitPieceQuestion ? 'Completar con mas' : ''}
                                </span>
                              )}
                            </div> */}
                            </div>
                          </div>
                        </Col>
                        <Col lg='6'>
                          <div className='d-flex align-items-center gap-6 justify-content-end'>
                            <Row className='justify-content-center align-items-center mt-3 mt-lg-0'>
                              <Col xs='12' lg='auto'>
                                {/* format */}
                                <span>
                                  <span className='me-2 fw-semibold'>
                                    {intl.formatMessage({id: 'CART.ITEM_PACKAGE_FORMAT'})}:
                                  </span>
                                  <span>
                                    {getProductFormat(
                                      item.packageUnit,
                                      item.stockRatio,
                                      intl.formatMessage({id: 'GENERAL.OF'}),
                                      item.code
                                    )}
                                  </span>
                                </span>
                              </Col>
                              <Col xs='12' lg='auto'>
                                {/* quantity */}
                                <span>
                                  <span className='me-2 fw-semibold'>
                                    {intl.formatMessage({id: 'CART.ITEM_QUANTITY'})}:
                                  </span>
                                  {item.quantity}
                                </span>
                              </Col>
                              <Col xs='12' lg='auto'>
                                {/* remove item button */}
                                <button
                                  className='btn p-0 mt-3 mt-lg-0'
                                  onClick={() => removeItem(item.code, item.stockRatio)}
                                >
                                  <span className='text-black-50 text-decoration-underline fw-semibold'>
                                    {intl.formatMessage({id: 'BUTTON.DELETE'})}
                                  </span>
                                </button>
                              </Col>
                            </Row>
                          </div>
                        </Col>
                      </Row>
                    </article>
                  </li>
                )
              })
            : null}
        </ul>
      </section>
    ) : (
      <section className='d-flex flex-column align-content-start p-lg-12'>
        <h3 className='fs-5 fw-medium'>{intl.formatMessage({id: 'ORDERS.CART_PRODUCTS'})}</h3>
        <p style={{color: '#99A1B7'}}>
          {intl.formatMessage({id: 'ORDERS.CREATE_NEW_ORDER_SHOPPING_CART_EMPTY'})}
        </p>
      </section>
    )
  )
}
