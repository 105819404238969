export const formatDate = (d: string) => {
  return new Date(d).toLocaleDateString('en-GB')
}
export const formatDateType = (d: Date, format?: string) => {
  return new Date(d).toLocaleDateString(format ? format : 'en-GB')
}

export const inputDateFormat = (d: string | Date) => {
  var dObj = new Date(d)
  var mm = (dObj.getMonth() + 1).toString().padStart(2, '0')
  var dd = dObj.getDate()
  var yyyy = dObj.getFullYear()
  return yyyy + '-' + mm + '-' + dd
}
