/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {LastOrder} from '../models/dashboard'
import {formatPrice} from '../helpers/formatPrice'
import {formatDate} from '../helpers/formatDate'
import {useIntl} from 'react-intl'
import {OrderStatus} from '../models/models'
import {Link} from 'react-router-dom'
import {KTIcon} from '../../_metronic/helpers'

type Props = {
  className: string
  lastOrders: LastOrder[]
}

export const LastOrdersTable: React.FC<Props> = ({className, lastOrders}) => {
  const intl = useIntl()

  return (
    <div className={`card h-100 ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>
            {intl.formatMessage({id: 'DASHBOARD.LAST_ORDERS_TABLE.TITLE'})}
          </span>
          <span className='text-muted mt-1 fw-semibold fs-7'>
            {intl.formatMessage({id: 'DASHBOARD.LAST_ORDERS_TABLE.SUBTITLE'})}
          </span>
        </h3>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bold text-muted'>
                <th>{intl.formatMessage({id: 'DASHBOARD.LAST_ORDERS_TABLE.CREATED'})}</th>
                <th>{intl.formatMessage({id: 'DASHBOARD.LAST_ORDERS_TABLE.EXPEDITION'})}</th>
                <th>{intl.formatMessage({id: 'DASHBOARD.LAST_ORDERS_TABLE.CLIENT'})}</th>
                <th>{intl.formatMessage({id: 'GENERAL_DELIVERY_ADDRESS'})}</th>
                <th>{intl.formatMessage({id: 'DASHBOARD.LAST_ORDERS_TABLE.TOTAL'})}</th>
                <th>{intl.formatMessage({id: 'DASHBOARD.LAST_ORDERS_TABLE.STATUS'})}</th>
                <th>{intl.formatMessage({id: 'DASHBOARD.LAST_ORDERS_TABLE.OPTIONS'})}</th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {lastOrders.length <= 0 && (
                <tr>
                  <td colSpan={6}>
                    <span className='text-muted'>
                      {intl.formatMessage({id: 'GENERAL.NO_DATA_AVAILABLE'})}
                    </span>
                  </td>
                </tr>
              )}
              {lastOrders.map((x) => (
                <tr key={x.guidId}>
                  <td>
                    <span className='text-muted fw-bold d-block mb-1 fs-6'>
                      {formatDate(x.dateCreated)}
                    </span>
                  </td>
                  <td>
                    <span className='text-muted fw-bold d-block mb-1 fs-6'>
                      {formatDate(x.expeditionDate)}
                    </span>
                  </td>
                  <td>
                    <span className='text-muted fw-bold d-block mb-1 fs-6'>
                      {x.detail.clientBusinessName}
                    </span>
                  </td>
                  <td>
                    <span className='text-muted fw-bold d-block mb-1 fs-6'>
                      {x.detail.shippingAddressDescription}
                    </span>
                  </td>
                  <td className='text-dark fw-bold text-hover-primary fs-6'>
                    {formatPrice(x.finalAmount)}
                  </td>
                  <td>
                    <span
                      className={`badge ${
                        x.status === OrderStatus.Pending
                          ? 'badge-light-warning'
                          : 'badge-light-success'
                      }`}
                    >
                      {intl.formatMessage({
                        id: 'ORDER.STATUS.' + OrderStatus[x.status].toUpperCase(),
                      })}
                    </span>
                  </td>
                  <td>
                    <Link
                      to={`/order-detail/${x.guidId}`}
                      className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                      title='View details'
                    >
                      <KTIcon iconName='eye' className='fs-3' />
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </div>
  )
}
