import {useIntl} from 'react-intl'
import {generateKeyFromString} from './utils'

export const useValidationTranslations = () => {
  const intl = useIntl()
  return {
    MessagePasswordRequiredFormat: intl.formatMessage({
      id: 'USER_PROFILE.PASSWORD.INFO_TEXT_FORMAT_REQUIRED',
    }),
    MessagePasswordRequired: intl.formatMessage({id: 'VALIDATIONS.PASSWORD_REQUIRED'}),
    MessagePasswordConfirmationRequired: intl.formatMessage({
      id: 'VALIDATIONS.PASSWORD_CONFIRMATION_REQUIRED',
    }),
    MessageEmailWrong: intl.formatMessage({
      id: 'VALIDATIONS.EMAIL_WRONG',
    }),
    MessageEmailRequired: intl.formatMessage({
      id: 'VALIDATIONS.EMAIL_REQUIRED',
    }),
    MessagePasswordAndPasswordConfirmationNotMatch: intl.formatMessage({
      id: 'VALIDATIONS.PASSWORD_AND_PASSWORD_CONFIRMATION_NOT_MATCH',
    }),
    MessagePasswordLength: intl.formatMessage({
      id: 'VALIDATIONS.PASSWORD_LENGTH',
    }),
    MessageLoginWrong: intl.formatMessage({
      id: 'VALIDATIONS.LOGIN_WRONG',
    }),
  }
}

export const useValidationAPITranslations = () => {
  const intl = useIntl()
  return (textFromAPI: string) => {
    return intl.formatMessage({
      id: `VALIDATIONS.${generateKeyFromString(textFromAPI)}`,
    })
  }
}
