import { useState } from 'react';
import { deleteCategory } from '../../requests/requests';
import { useIntl } from 'react-intl';
import { ConfirmDeleteModal } from '../../components/generic-confirm/Confirm';
import EditCategoryModal from '../../components/edit-category/editCategoryModal';

function SingleCategory({ id, name, refetch }) {
  const intl = useIntl();
  const [editShow, setEditShow] = useState(false);
  const [deleteShow, setDeleteShow] = useState(false);

  const handleConfirmDelete = () => {
    deleteCategory(parseInt(id)).then(() => { refetch(); });
  }

  return (
    <tr>
      <td>
      <ConfirmDeleteModal show={deleteShow} onHide={() => setDeleteShow(false)} onConfirm={handleConfirmDelete} title={intl.formatMessage({id: 'CONFIRM_TEXT'})} message={intl.formatMessage({id: 'BUTTON.CONFIRM_DELETE'})}/>
      <EditCategoryModal
          id={id}
          show={editShow} 
          onHide={
            () => setEditShow(false)
          } 
          refetchNews={refetch}
        />
        <div className='d-flex align-items-center'>
            <h5 className='fw-bold'>
              {name}
            </h5>
        </div>
      </td>
      <td className='text-start'><span className="badge badge-secondary">{name}</span></td>
      <td className='text-end d-flex justify-content-end gap-4'>
        <span className='text-decoration-underline fw-semibold' onClick={() => setEditShow(true)}>{intl.formatMessage({id: 'BUTTON.EDIT'})}</span>
        <span className='text-decoration-underline fw-semibold'onClick={() => setDeleteShow(true)}>{intl.formatMessage({id: 'BUTTON.DELETE'})}</span>
      </td>
    </tr>
  )
}

export default SingleCategory;
