import {useQuery} from 'react-query'
import {requestPublicNews} from '../../requests/requests'
import DashboardSingleNew from '../news/PublicSingleNews'
import {useIntl} from 'react-intl'
import {Link} from 'react-router-dom'
import {configStaleTime} from '../../constants'

function DashboardNews() {
  const intl = useIntl()
  const {isLoading, error, data} = useQuery('dashboardNews', () => requestPublicNews(), {
    refetchOnWindowFocus: false,
    staleTime: configStaleTime,
  })

  const getTimeOfDate = (date?: Date) => {
    return date != null ? new Date(date).getTime() : 0
  }

  const newsData =
    data &&
    data.data &&
    data.data.sort((a, b) => {
      return getTimeOfDate(b.dateCreated) - getTimeOfDate(a.dateCreated)
    })

  return (
    <div className='card d-flex justify-content-center justify-content-xl-start flex-row-auto w-100 me-9 mt-4'>
      <section className='card-body p-lg-20'>
        <div className='d-flex flex-stack mb-5'>
          <h2 className='mb-7'>{intl.formatMessage({id: 'NEWS.NEWS_TITLE'})}</h2>
          <Link to='/news' className='text-center text-decoration-underline fs-4 fw-medium'>
            {intl.formatMessage({id: 'DASHBOARD.LINK_TO_ALL_NEWS'})}
          </Link>
        </div>
        <div className='separator separator-dashed mb-9'></div>

        <div className='row gx-10 gy-10'>
          {isLoading ? (
            <p>{intl.formatMessage({id: 'FETCH.LOADING'})}</p>
          ) : error ? (
            <p>{intl.formatMessage({id: 'FETCH.ERROR'})}</p>
          ) : (
            newsData &&
            newsData.map(
              (article: any, index) =>
                index < 3 && (
                  <DashboardSingleNew
                    id={article.id}
                    title={article.title}
                    content={article.description}
                    image={article.urlImage}
                    date={article.dateCreated}
                    externalLink={article.externalLink}
                    key={article.id}
                  />
                )
            )
          )}
        </div>
      </section>
    </div>
  )
}

export default DashboardNews
