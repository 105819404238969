import {useState} from 'react'
import {Button, Col, Image, Row} from 'react-bootstrap'
import {Link} from 'react-router-dom'
import {QuillEditor} from '../../components/QuillEditor'

function DashboardSingleNew({title, image, content, id, date, externalLink}) {
  const [editorValue, setEditorValue] = useState(content)

  return (
    <article className='col-12 col-md-6 col-xl-4 mb-6' id={id} key={id}>
      <Row>
        <Col>
          <Image
            className='mb-3 bg-secondary rounded'
            fluid
            src={image}
            alt={title}
            style={{aspectRatio: '16/9', objectFit: 'cover', height: '305px'}}
          />
          <span className='text-black-60 fw-medium text-muted d-flex align-items-center justify-content-end mb-3'>
            {new Date(date).toLocaleDateString('en-GB')}
          </span>
          <h3 className='lh-base'>{title}</h3>
          <div className='text-black-50 fs-5 fw-medium lh-lg'>
            <QuillEditor value={editorValue} setValue={setEditorValue} readOnly={true} />
          </div>
          {externalLink && (
            <Link to={externalLink} target='_blank'>
              <Button
                type='button'
                variant='primary'
                className='mt-5 w-100 w-lg-50 mx-auto d-block'
              >
                Leer más
              </Button>
            </Link>
          )}
        </Col>
      </Row>
    </article>
  )
}

export default DashboardSingleNew
