import {useState} from 'react'
import {useLocation} from 'react-router-dom'
import {useQueryClient} from 'react-query'
import {useNavigateHelper} from '../hooks/useNavigateHelper'
import {useShoppingCart} from '../context/ShoppingCartContext'
import {ConfirmNotificationModal} from '../components/generic-confirm/ConfirmNotification'
import {ConfirmLeaveModal} from '../components/generic-confirm/ConfirmLeave'
import {ConfirmValidatedOrdersModal} from '../components/generic-confirm/ConfirmValidatedOrders'
import {useSaveFilters} from '../hooks/useSaveFilters'

export const LeaveNavigationWatcher = ({children}) => {
  const location = useLocation()
  const queryClient = useQueryClient()
  const navigateHelper = useNavigateHelper()
  const {checkSaveFilters} = useSaveFilters()
  const [skip, setSkip] = useState(false)
  const {cartItems, clearCart} = useShoppingCart()
  const orderSended = localStorage.getItem('orderSended') ? true : false
  const ordersValidated = localStorage.getItem('ordersValidated') ? true : false
  const [showNotyOrderSended, setShowNotyOrderSended] = useState(orderSended)
  const [showNotyOrdersValidated, setShowNotyOrdersValidated] = useState(ordersValidated)
  if (skip) {
    setSkip(false) // Reset
  }
  if (orderSended && !showNotyOrderSended) {
    setShowNotyOrderSended(true)
  }
  if (ordersValidated && !showNotyOrdersValidated) {
    setShowNotyOrdersValidated(true)
  }
  const inProcess =
    localStorage.getItem('selectedClientID') &&
    cartItems.length > 0 &&
    location.pathname !== '/orders'
  localStorage.setItem('currentPathName', location.pathname)
  checkSaveFilters()
  return (
    <>
      {!location.pathname.startsWith('/auth') && (
        <>
          <ConfirmNotificationModal
            show={showNotyOrderSended}
            setShow={setShowNotyOrderSended}
          ></ConfirmNotificationModal>
          <ConfirmValidatedOrdersModal
            show={showNotyOrdersValidated}
            setShow={setShowNotyOrdersValidated}
          ></ConfirmValidatedOrdersModal>
        </>
      )}
      {inProcess && !skip && (
        <div>
          <ConfirmLeaveModal
            show={inProcess && !skip}
            onCancel={() => {
              navigateHelper('/orders')
            }}
            onConfirm={() => {
              setSkip(true)
              localStorage.removeItem('selectedClientID')
              localStorage.removeItem('selectedExpeditionDate')
              queryClient.removeQueries('selectedUser', {exact: true})
              queryClient.removeQueries('selectedAddress', {exact: true})
              queryClient.removeQueries('selectedDate', {exact: true})
              clearCart()
            }}
          />
        </div>
      )}
      {children}
    </>
  )
}
