import {Modal, Button, Form} from 'react-bootstrap';
import {Formik, Field} from 'formik';
import {useIntl} from 'react-intl';
import { AddCategoryModel, CategoryName, Language } from '../../models/models';
import { addCategory } from '../../requests/requests';

function AddCategoryModal({show, onHide, refetch}) {
  const intl = useIntl()

  return (
    <Modal show={show} onHide={onHide} dialogClassName='modal-dialog modal-dialog-centered'>
      <Modal.Header closeButton>
        <h2 className='text-black'>
          {intl.formatMessage({id: 'CATEGORIES.MODAL_CATEGORIES.ADD_CATEGORIES_PAGE_TITLE'})}
        </h2>
      </Modal.Header>
      <Modal.Body className='modal-body py-lg-10 px-lg-20'>
        <Formik
          initialValues={{nameES: '', nameEN: ''}}
          onSubmit={({nameES, nameEN}) => {
            const addCategoryObj = {
              name: [
                {value: nameEN, language: Language.English} as CategoryName,
                {value: nameES, language: Language.Spanish} as CategoryName,
              ],
            } as AddCategoryModel
            addCategory(addCategoryObj).then(() => {
              refetch()
              onHide()
            })
          }}
        >
          {({handleSubmit}) => (
            <Form onSubmit={handleSubmit}>
              <Form.Group controlId='nameES' className='d-flex flex-column mb-8'>
                <Form.Label className='fs-4 text-black'>
                  {intl.formatMessage({id: 'CATEGORIES.MODAL_CATEGORIES.LABEL_NAME'})}{' '}
                  {intl.formatMessage({id: 'CATEGORIES.MODAL_CATEGORIES.LANGUAGE_ES'})}
                </Form.Label>
                <Field
                  type='text'
                  name='nameES'
                  className='form-control bg-light border-0 text-black'
                  required
                />
              </Form.Group>

              <Form.Group controlId='nameEN' className='d-flex flex-column mb-8'>
                <Form.Label className='fs-4 text-black'>
                  {intl.formatMessage({id: 'CATEGORIES.MODAL_CATEGORIES.LABEL_NAME'})}{' '}
                  {intl.formatMessage({id: 'CATEGORIES.MODAL_CATEGORIES.LANGUAGE_EN'})}
                </Form.Label>
                <Field
                  type='text'
                  name='nameEN'
                  className='form-control bg-light border-0 text-black'
                />
              </Form.Group>
              <div className='d-flex justify-content-end'>
                <Button
                  type='reset'
                  className='btn btn-secondary btm-sm mt-8  me-4'
                  onClick={onHide}
                >
                  {intl.formatMessage({id: 'BUTTON.CANCEL'})}
                </Button>
                <Button variant='primary' type='submit' className='btn btn-primary btm-sm mt-8'>
                  {intl.formatMessage({id: 'CATEGORIES.MODAL_CATEGORIES.BUTTON_SAVE'})}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  )
}

export default AddCategoryModal
