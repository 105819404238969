import React, { FC } from 'react';

type Props = {
    name: string
    value: boolean
    checked: boolean
    setValues: (values: React.SetStateAction<any>, shouldValidate?: boolean) => void;
    controlId: string;
}

const RadioButtonBooleanField: FC<Props> = ({name, value, checked, setValues, controlId = ""}) => {
    return (
        <input type='radio' className="form-check-input" name={name} value={String(value)} checked={checked} id={controlId}
        onChange={(e: any) => {
            setValues((prevValues: any) => ({
            ...prevValues,
            [e.target.name]: e.target.value === "true"
            }))
            }
        } 
        />
    );
};

  export {RadioButtonBooleanField};