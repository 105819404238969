import {Fragment} from 'react'
import {Link} from 'react-router-dom'

export const PaginationWrapper = ({
  currentPage,
  chunkedArray,
  handlePaginationNumberClick,
  handleNextClick,
  handlePreviousClick,
  productSearch = '',
  paginationWrapperClass = '',
}: {
  currentPage: any
  chunkedArray: any
  handlePaginationNumberClick: any
  handleNextClick: any
  handlePreviousClick: any
  productSearch?: string
  paginationWrapperClass?: string
}) => {
  if (chunkedArray?.length <= 1) return <></>
  const limitSimplePagination = 10
  const paginatedList = chunkedArray.map((x, index) => ({data: x, page: index + 1}))
  const paginatedListFirst = paginatedList.slice(0, 5)
  const paginatedListLast = paginatedList.slice(-5)
  const paginatedListHaveCurrent =
    paginatedListFirst.find((x) => x.page === currentPage) != null ||
    paginatedListLast.find((x) => x.page === currentPage) != null
  return (
    <>
      <ul className={`pagination ${paginationWrapperClass}`}>
        {chunkedArray.length > limitSimplePagination && (
          <span className='page-item' onClick={() => handlePaginationNumberClick(1)}>
            <Link to={'#'} className='page-link'>
              <i className='previous' />
              <i className='previous' />
            </Link>
          </span>
        )}

        <li className='page-item previous' onClick={() => handlePreviousClick()}>
          <Link to={'#'} className='page-link'>
            <i className='previous' />
          </Link>
        </li>

        {chunkedArray.length > limitSimplePagination && (
          <>
            {paginatedListFirst.map((newsChunk: any, index: number) => {
              const page = newsChunk.page
              return (
                <li
                  onClick={() => handlePaginationNumberClick(page)}
                  key={index}
                  className={`page-item ${
                    productSearch.length > 0 && currentPage === 0 && currentPage === 1
                      ? 'active-search'
                      : ''
                  } ${currentPage === page ? 'active' : ''}`}
                  data-page={page}
                >
                  <Link to={'#'} className='page-link'>
                    {page}
                  </Link>
                </li>
              )
            })}
            {!paginatedListHaveCurrent && (
              <Fragment>
                <span className={`page-item active`}>
                  <Link to='#' className='page-link'>
                    {currentPage}
                  </Link>
                </span>
              </Fragment>
            )}
            {paginatedListHaveCurrent && (
              <Fragment>
                <span className={`page-item`}>
                  <Link to='#' className='page-link'>
                    ...
                  </Link>
                </span>
              </Fragment>
            )}
            {paginatedListLast.map((newsChunk: any, index: number) => {
              const page = newsChunk.page
              return (
                <li
                  onClick={() => handlePaginationNumberClick(page)}
                  key={index}
                  className={`page-item ${
                    productSearch.length > 0 && currentPage === 0 && currentPage === 1
                      ? 'active-search'
                      : ''
                  } ${currentPage === page ? 'active' : ''}`}
                  data-page={page}
                  data-test={`currentPage ${currentPage} page ${page}`}
                >
                  <Link to={'#'} className='page-link'>
                    {page}
                  </Link>
                </li>
              )
            })}
          </>
        )}
        {chunkedArray.length <= limitSimplePagination && (
          <>
            {paginatedList.map((newsChunk: any, index: number) => {
              const page = newsChunk.page
              return (
                <li
                  onClick={() => handlePaginationNumberClick(page)}
                  key={index}
                  className={`page-item ${
                    productSearch.length > 0 && currentPage === 0 && currentPage === 1
                      ? 'active-search'
                      : ''
                  } ${currentPage === page ? 'active' : ''}`}
                  data-page={page}
                  data-test={`currentPage ${currentPage} page ${page}`}
                >
                  <Link to={'#'} className='page-link'>
                    {page}
                  </Link>
                </li>
              )
            })}
          </>
        )}

        <li className='page-item next' onClick={() => handleNextClick()}>
          <Link to={'#'} className='page-link'>
            <i className='next' />
          </Link>
        </li>

        {chunkedArray.length > limitSimplePagination && (
          <>
            <span
              className='page-item next'
              onClick={() => handlePaginationNumberClick(chunkedArray.length)}
            >
              <Link to={'#'} className='page-link'>
                <i className='next' />
                <i className='next' />
              </Link>
            </span>
          </>
        )}
      </ul>
    </>
  )
}
