//import {useState} from 'react'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import 'react-quill/dist/quill.bubble.css'

export const QuillEditor = ({value, setValue, readOnly = false}) => {
  return (
    <ReactQuill
      theme={`${readOnly ? 'bubble' : 'snow'}`}
      value={value}
      onChange={setValue}
      readOnly={readOnly}
    />
  )
}
