import {Modal, Button, Form} from 'react-bootstrap'
import {Formik, Field} from 'formik'
import {useState} from 'react'
import {useIntl} from 'react-intl'
import {useQuery} from 'react-query'
import {editNewsModel, requestPublicNews} from '../../requests/requests'
import FileInput from '../../helpers/imgToBase64'
import {QuillEditor} from '../QuillEditor'

function EditNewsModal({
  id,
  title,
  description,
  image,
  enable,
  lang,
  externalLink,
  show,
  onHide,
  refetchNews,
}) {
  const [isCheked, setIsCheked] = useState(enable)
  const intl = useIntl()
  const [editorValue, setEditorValue] = useState(description)
  const {refetch: refetchPublicNews} = useQuery('dashboardNews', () => requestPublicNews(), {
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 5,
  }) // For refresh data after update

  return (
    <Modal
      show={show}
      onHide={onHide}
      dialogClassName='modal-dialog modal-dialog-centered mw-900px'
    >
      <Modal.Header closeButton>
        <h2 className='text-black'>
          {intl.formatMessage({id: 'NEWS.MODAL_NEWS.EDIT_NEWS_PAGE_TITLE'})}
        </h2>
      </Modal.Header>

      <Modal.Body className='modal-body py-lg-10 px-lg-20'>
        <Formik
          initialValues={{
            id: id,
            title: title,
            description: description,
            image: '',
            imageBs64: '',
            lang: lang,
            enable: enable,
            externalLink: externalLink ? externalLink : '',
          }}
          onSubmit={({id, title, imageBs64, lang, externalLink}) => {
            lang = Number(lang)
            editNewsModel({
              id,
              title,
              description: editorValue,
              imageBs64,
              lang,
              enable: isCheked,
              externalLink: externalLink,
            }).then(() => {
              refetchNews()
              onHide()
              refetchPublicNews()
            })
          }}
        >
          {({handleSubmit}) => (
            <Form onSubmit={handleSubmit}>
              <Form.Group className='d-flex flex-column mb-8'>
                <Form.Label className='fs-4 text-black'>
                  {intl.formatMessage({id: 'NEWS.MODAL_NEWS.LABEL_TITLE'})}
                </Form.Label>
                <Field
                  type='text'
                  name='title'
                  className='form-control bg-light border-0 text-black'
                />
                <span className='text-gray-400 mt-2'>
                  {intl.formatMessage({id: 'NEWS.MODAL_NEWS.TEXTLABEL_TITLE'})}
                </span>
              </Form.Group>
              <Form.Group className='d-flex flex-column mb-8'>
                <Form.Label className='fs-4 text-black'>
                  {intl.formatMessage({id: 'NEWS.MODAL_NEWS.LABEL_CONTENT'})}
                </Form.Label>
                <QuillEditor value={editorValue} setValue={setEditorValue} />
                {/* <Field
                  as='textarea'
                  name='description'
                  className='form-control w-100 h-auto bg-light border-0 text-black'
                  rows='8'
                  style={{resize: 'none'}}
                /> */}
                <span className='text-gray-400 mt-2'>
                  {intl.formatMessage({id: 'NEWS.MODAL_NEWS.TEXTLABEL_CONTENT'})}
                </span>
              </Form.Group>
              <Form.Group className='d-flex flex-column mb-8'>
                <Form.Label className='fs-4 text-black'>
                  {intl.formatMessage({id: 'NEWS.MODAL_NEWS.LABEL_CURRENT_IMAGE'})}
                </Form.Label>
                <img
                  className='mb-4'
                  src={image}
                  alt=''
                  height={100}
                  style={{
                    maxWidth: '300px',
                    height: 'auto',
                    aspectRatio: 16 / 9,
                    objectFit: 'cover',
                    borderRadius: '6px',
                  }}
                />
              </Form.Group>

              <Form.Group className='d-flex flex-column mb-8'>
                <Form.Label className='fs-4 text-black'>
                  {intl.formatMessage({id: 'NEWS.MODAL_NEWS.LABEL_NEW_IMAGE'})}
                </Form.Label>
                <FileInput />
                <span className='text-gray-400 mt-2'>
                  {intl.formatMessage({id: 'NEWS.MODAL_NEWS.TEXTLABEL_IMAGE'})}
                </span>
              </Form.Group>

              <Form.Group className='d-flex flex-column my-8'>
                <Form.Label className='fs-4 text-black'>
                  {intl.formatMessage({id: 'NEWS.MODAL_NEWS.LABEL_LANGUAGE'})}
                </Form.Label>
                <Field
                  as='select'
                  name='lang'
                  className='form-control max-w-200 position-relative bg-light border-0 text-black'
                >
                  <option value='0'>
                    {intl.formatMessage({id: 'NEWS.MODAL_NEWS.LANGUAGE_EN'})}
                  </option>
                  <option value='1'>
                    {intl.formatMessage({id: 'NEWS.MODAL_NEWS.LANGUAGE_ES'})}
                  </option>
                </Field>
                <span className='text-gray-400 mt-2'>
                  {intl.formatMessage({id: 'NEWS.MODAL_NEWS.TEXTLABEL_LANGUAGE'})}
                </span>
              </Form.Group>

              <Form.Group className='d-flex flex-column mb-8'>
                <Form.Label className='fs-4 text-black'>
                  {intl.formatMessage({id: 'NEWS.MODAL_NEWS.LABEL_EXTERNAL_LINK'})}
                </Form.Label>
                <Field
                  type='text'
                  name='externalLink'
                  className='form-control bg-light border-0 text-black'
                />
                <span className='text-gray-400 mt-2'>
                  {intl.formatMessage({id: 'NEWS.MODAL_NEWS.TEXTLABEL_EXTERNAL_LINK'})}
                </span>
              </Form.Group>

              <Form.Group className='d-flex align-items-center'>
                <Field
                  type='checkbox'
                  name='enable'
                  id='enableCheck'
                  className='d-flex justify-content-start'
                  style={{accentColor: '#071426'}}
                  checked={isCheked}
                  onChange={(e: any) => setIsCheked(e.target.checked)}
                />

                <Form.Label className='mb-0 mx-2 fs-4 text-black' htmlFor='enableCheck'>
                  {intl.formatMessage({id: 'NEWS.MODAL_NEWS.LABEL_ENABLED'})}
                </Form.Label>
              </Form.Group>
              <span className='text-gray-400 mt-2'>
                {intl.formatMessage({id: 'NEWS.MODAL_NEWS.TEXTLABEL_ENABLE'})}
              </span>

              <div className='d-flex justify-content-end'>
                <Button
                  type='reset'
                  className='btn btn-secondary btm-sm mt-8  me-4'
                  onClick={onHide}
                >
                  {intl.formatMessage({id: 'BUTTON.CANCEL'})}
                </Button>
                <Button
                  variant='primary'
                  type='submit'
                  className='btn btn-primary btm-sm mt-8'
                  data-toggle='tooltip'
                  title={intl.formatMessage({id: 'BUTTON.SAVE'})}
                >
                  {intl.formatMessage({id: 'BUTTON.SAVE'})}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  )
}

export default EditNewsModal
