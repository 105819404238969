import { FC, useEffect, useState } from 'react'
import { useQuery, useQueryClient } from 'react-query'
import { confirmOrders, requestAllDistributors } from '../../requests/requests'
import { OrderStatus, PendingOrder, Summary, ViewOrdersModel } from '../../models/models'
import { KTIcon, KTSVG } from '../../../_metronic/helpers'
import { Link, useLocation } from 'react-router-dom'
import ValidateOrdersRow from './components/ValidateOrdersRow'
import { useIntl } from 'react-intl'
import { Button, Col, Row, Spinner } from 'react-bootstrap'
import SuperSelect from '../../components/SuperSelect'
import { useSortOrdersToValidate } from '../../hooks/useSortOrdersToValidate'
import { useNavigateHelper } from '../../hooks/useNavigateHelper'
import { getProductFormat } from '../../hooks/getProductFormat'
import { useQueryValidateOrders } from '../../hooks/useQueryValidateOrders'
import { FilterByDate } from '../../components/FilterByDate'
import { formatDate, formatDateType } from '../../helpers/formatDate'
import { FilterByStatus } from '../../components/FilterByStatus'
import { useSaveFilters } from '../../hooks/useSaveFilters'
import { CustomerWithID } from '../../models/allOrdersWithFilters'
import { ToNormalizedState } from '../../helpers/utils'
import { SaveFilterForcedValue } from '../../helpers/filterForcedValue'
import { defaultDateForValidateOrders } from '../../constants'

const ViewAllOrders: FC = () => {
  const queryClient = useQueryClient()
  const intl = useIntl()
  const [selectedOrders, setSelectedOrders] = useState<string[]>([])
  const [recoveredFilter, setRecoveredFilter] = useState<boolean>(false)
  const { saveFilter, getSavedFilter, existSavedFilter, removeSavedFilter } = useSaveFilters()
  const navigate = useNavigateHelper()
  const location = useLocation()
  const [filteredDate, setFilteredDate] = useState(
    localStorage.getItem('filteredDate')
      ? new Date(localStorage.getItem('filteredDate') as string)
      : new Date()
  )
  const [validatingOrder, setValidatingOrder] = useState<boolean>(false)
  const [validatingIndividualOrder, setValidatingIndividualOrder] = useState<string>('')
  const { isFetchingValidateOrders, refetchValidateOrders } = useQueryValidateOrders(filteredDate)
  useQuery<CustomerWithID[]>(
    ['allDistributors'],
    async () => {
      return requestAllDistributors().then(({ data: x }) => {
        return x
      })
    },
    { staleTime: 60 * 60 * 24 * 365 }
  )
  const allUsers: CustomerWithID[] = queryClient.getQueryData([
    'allDistributors',
  ]) as CustomerWithID[]
  const allOrders: ViewOrdersModel = queryClient.getQueryData([
    'ordersToValidate',
  ]) as ViewOrdersModel
  let orderStates = [{}]
  if (allOrders) {
    // test
    // allOrders.orders.filter((x) => {
    //   if (x.status === 1) {
    //     x.state = 'Received'
    //   }
    //   return x
    // })
    // Get a state for filters
    const orderGroupByState = allOrders.orders.filter((value, index, array) => {
      return (
        array.findIndex((x) => x.sageOrderStatus === value.sageOrderStatus) === index &&
        value.sageOrderStatus != null
      )
    })
    orderStates = orderGroupByState.map((x) => ({
      value: x.sageOrderStatus,
      label: x.sageOrderStatus,
    }))
  }
  const {
    allOrdersFiltered,
    customerOptions,
    setFilteredUser,
    filteredUser,
    handleChangeSelectedUser,
    filteredStatus,
    setFilteredStatus,
  } = useSortOrdersToValidate()

  const anyFilteredStatusValid =
    (typeof filteredStatus === 'number' && filteredStatus >= 0) ||
    typeof filteredStatus === 'string'
  const filteredStatusLbl = isNaN(parseInt(filteredStatus) as number)
    ? ToNormalizedState(filteredStatus as string)
    : OrderStatus[filteredStatus as string]
  const currentFilteredUser = (): CustomerWithID => {
    if (!allUsers) return {} as CustomerWithID
    const customer: CustomerWithID | undefined = allUsers.find((x) => x.id === filteredUser)
    if (customer) {
      SaveFilterForcedValue(filteredUser, 'forcedValueClient')
      return customer
    }
    return {} as CustomerWithID
  }
  const totalKG = (summary: Summary) => {
    const totalKg = summary?.orders?.reduce(
      (acc, currentValue) => acc + currentValue.stockRatio * currentValue.total,
      0
    )
    return totalKg
  }

  const handleChangeChecked = () => {
    const elTableValidateOrders = document.getElementById('table_validate_orders')
    if (elTableValidateOrders) {
      const elChecks = elTableValidateOrders.querySelectorAll(
        '.form-check-input:not(.form-check-input-manager)'
      ) as NodeListOf<HTMLInputElement>
      const elChecksManager = elTableValidateOrders.querySelector(
        '.form-check-input-manager'
      ) as HTMLInputElement
      if (elChecksManager) {
        elChecks.forEach((el) => {
          el.checked = elChecksManager.checked
        })
        setOrdersSelection()
      }
    }
  }

  const setOrdersSelection = () => {
    setSelectedOrders([])
    const elTableValidateOrders = document.getElementById('table_validate_orders')
    if (elTableValidateOrders) {
      const elChecks = elTableValidateOrders.querySelectorAll(
        '.form-check-input:not(.form-check-input-manager)'
      ) as NodeListOf<HTMLInputElement>
      let newSelectedOrders: string[] = []
      elChecks.forEach((el) => {
        const orderIdToValidateEl = el.closest('tr[data-id]')
        if (orderIdToValidateEl && el.checked) {
          const orderIdToValidate = orderIdToValidateEl.getAttribute('data-id') as string
          newSelectedOrders.push(orderIdToValidate)
        }
      })
      setSelectedOrders(newSelectedOrders)
    }
  }

  const validateSelectedOrders = async () => {
    setValidatingOrder(true)
    const confirmedOrders = await confirmOrders(selectedOrders)
    setValidatingOrder(false)
    localStorage.setItem('ordersValidated', JSON.stringify(confirmedOrders.data))
    await refetchValidateOrders()
    navigate(location.pathname)
  }

  const allIsValidated = (summaryObj: Summary) => {
    let allValidated = true
    for (let x = 0; x < summaryObj.orders.length; x++) {
      const orderObj = summaryObj.orders[x]
      const orderObjX = allOrders.orders.find((x) => x.guidId === orderObj.orderGuidId)
      if (orderObjX && orderObjX.status === OrderStatus.Pending) {
        allValidated = false
      }
    }
    return allValidated
  }

  const noNeedCheckSelect = () => {
    return allOrders && allOrders.summary.every((x) => allIsValidated(x))
  }

  // The valid method for run refetch every filteredDate change
  useEffect(() => {
    if (isNaN(filteredDate as any)) {
      localStorage.setItem('filteredDate', new Date().toISOString().split('T')[0])
    } else {
      localStorage.setItem('filteredDate', filteredDate.toISOString().split('T')[0])
    }
    refetchValidateOrders()
  }, [filteredDate, refetchValidateOrders])

  useEffect(() => {
    if (!recoveredFilter) {
      setRecoveredFilter(true)
      if (existSavedFilter('filteredUser')) {
        const filtered = getSavedFilter('filteredUser')
        setFilteredUser(filtered)
      }
      if (existSavedFilter('filteredStatus')) {
        const filtered = getSavedFilter('filteredStatus')
        setFilteredStatus(filtered)
      }
      if (existSavedFilter('filteredExpeditionDate')) {
        const filtered = new Date(getSavedFilter('filteredExpeditionDate'))
        setFilteredDate(filtered)
      }
    }
  }, [
    existSavedFilter,
    getSavedFilter,
    recoveredFilter,
    setFilteredDate,
    setFilteredStatus,
    setFilteredUser,
  ])

  return (
    <>
      <section className='card mb-6 pb-3'>
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>
              {intl.formatMessage({ id: 'VALIDATE_ORDERS.FILTER_BY_EXPEDITION_DATE' })}
            </span>
            <span className='text-muted mt-1 fw-semibold fs-7'>
              {intl.formatMessage({ id: 'GENERAL_SHOWING_ORDERS_OF' })}{' '}
              {formatDate(filteredDate.toDateString())}
            </span>
          </h3>
        </div>
        <div className='card-body'>
          <FilterByDate
            onSubmit={async (values) => {
              if (values['selectedDate'].length <= 0) {
                values['selectedDate'] = defaultDateForValidateOrders.toISOString().split('T')[0];
              }
              setFilteredDate(new Date(values['selectedDate']))
            }}
            filteredDate={filteredDate}
            setFilteredDate={setFilteredDate}
          />
        </div>
      </section>
      {/* ACCORDION */}
      <section className='card mb-6 pb-3'>
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3'>
              <h3>{intl.formatMessage({ id: 'VALIDATE_ORDERS.TITLE' })}</h3>
            </span>
          </h3>
        </div>
        <div className='card-body py-0'>
          {isFetchingValidateOrders && <span>{intl.formatMessage({ id: 'FETCH.LOADING' })}</span>}

          <div className='accordion accordion-icon-toggle' id='validate_accordion'>
            {/* {allOrders && allOrders.summary.every((x) => allIsValidated(x)) && (
              <span>{intl.formatMessage({id: 'FETCH.NO_RESULTS'})}</span>
            )} */}

            {allOrders &&
              allOrders?.summary?.map((product: Summary, index) => {
                return (
                  <div key={`wrapper-summary-${index}`}>
                    <div
                      className='accordion-header collapsed py-3 d-flex'
                      data-bs-toggle='collapse'
                      data-bs-target={`#validate_accordion_item_${index}`}
                    >
                      <span className='accordion-icon'>
                        <KTSVG
                          className='svg-icon svg-icon-4'
                          path='media/icons/duotune/arrows/arr064.svg'
                        />
                      </span>
                      <h3 className='fs-4 text-gray-800 fw-bold mb-0 ms-4'>
                        <span>{product.productNameAdmin}</span>
                        <span> | </span>
                        <span>
                          {intl.formatMessage({ id: 'MY_ORDER_DETAILS.TABLE_HEADER_TOTAL' })}:{' '}
                          {product.total} {getProductFormat(product.packageUnit)} |{' '}
                          {totalKG(product)}{' '}
                          {product.packageUnit === 'CAJ' || product.packageUnit === 'PZA'
                            ? 'KG'
                            : 'UN'}
                        </span>
                      </h3>
                    </div>
                    <div
                      id={`validate_accordion_item_${index}`}
                      className='fs-6 collapse ps-lg-10'
                      data-bs-parent={`#validate_accordion_item_${index}`}
                    >
                      <div
                        className='accordion accordion-icon-toggle'
                        id='validate_accordion_submenu'
                      >
                        {/* LIST*/}
                        <ul className='p-0'>
                          {product?.orders?.map((order, index) => {
                            const orderObj = allOrders.orders.find(
                              (x) => x.guidId === order.orderGuidId
                            )
                            return (
                              <li
                                key={`summary-orders-${order.orderGuidId}-${index}`}
                                className='card d-flex flex-row justify-content-between align-items-center py-2 px-4 mb-2'
                              >
                                <span className='w-100 w-lg-auto'>
                                  {order.total}{' '}
                                  {getProductFormat(
                                    order.packageUnit,
                                    order.stockRatio,
                                    intl.formatMessage({ id: 'GENERAL.OF' }),
                                    ''
                                  )}{' '}
                                </span>
                                <span className='w-100 w-lg-auto'>
                                  {order.customerBusinessName} | {order.clientBusinessName}
                                </span>
                                {/* VALIDATED */}
                                {orderObj && orderObj.status === OrderStatus.Validated && (
                                  <div className='d-flex align-items-center gap-4'>
                                    <span className='badge badge-light-success'>
                                      {intl.formatMessage({
                                        id:
                                          'ORDER.STATUS.' +
                                          OrderStatus[orderObj.status].toUpperCase(),
                                      })}
                                    </span>
                                  </div>
                                )}
                                {/* EDIT */}
                                {orderObj && orderObj.status === OrderStatus.Pending && (
                                  <div className='d-flex align-items-center gap-4'>
                                    <Link
                                      to={`/order-edit/${order.orderGuidId}`}
                                      className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                      title='Edit'
                                    >
                                      <KTIcon iconName='pencil' className='fs-3' />
                                    </Link>
                                  </div>
                                )}
                              </li>
                            )
                          })}
                        </ul>
                      </div>
                    </div>
                  </div>
                )
              })}
          </div>
        </div>
      </section>
      {/* VALIDATION TABLE */}
      <div className={`card`}>
        {/* begin::Header */}
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>
              {intl.formatMessage({ id: 'VALIDATE_ORDERS.TABLE_TITLE' })}
            </span>
            {(filteredDate || anyFilteredStatusValid || filteredUser.length > 0) && (
              <span className='badge badge-dark mt-3'>
                {intl.formatMessage({ id: 'GENERAL_SHOWING_ORDERS_OF' })}{' '}
                {filteredUser.length > 0 && <>{currentFilteredUser().businessName}</>}
                {filteredUser.length <= 0 && <>{intl.formatMessage({ id: 'GENERAL_ALL_CLIENTS' })}</>}
                {filteredStatus && anyFilteredStatusValid && filteredStatusLbl && (
                  <span>
                    &nbsp;[
                    {intl.formatMessage({
                      id: 'ORDER.STATUS.' + filteredStatusLbl.toLocaleUpperCase(),
                    })}
                    ]
                  </span>
                )}
                {filteredDate && <>&nbsp; - {formatDateType(filteredDate)}</>}
              </span>
            )}
          </h3>

          <Row className='justify-content-center align-items-center w-100'>
            <Col>
              <FilterByStatus
                translator={intl}
                handleChangeSelected={(x) => {
                  saveFilter('filteredStatus', x.value)
                  setFilteredStatus(x.value)
                }}
                sageStatusInFilter={orderStates}
              >
                {filteredStatus.length > 0 && (
                  <Button
                    variant='primary'
                    type='button'
                    className='ms-3'
                    onClick={() => {
                      setFilteredStatus('')
                      removeSavedFilter('filteredStatus')
                    }}
                  >
                    {intl.formatMessage({ id: 'MY_ORDERS_FILTER_CLEAR' })}
                  </Button>
                )}
              </FilterByStatus>
            </Col>
            <Col>
              <div className='card-toolbar justify-content-end'>
                <div className='me-3 mb-3 mb-lg-0'>
                  <KTIcon iconName='user' className='fs-3' />
                  <span className='ms-3'>
                    {intl.formatMessage({ id: 'ALL_ORDERS_WITH_FILTERS_FILTER_BY_CUSTOMER' })}
                  </span>
                </div>
                <SuperSelect
                  options={customerOptions()}
                  onChange={handleChangeSelectedUser}
                  placeholder={intl.formatMessage({ id: 'GENERAL_SELECT_ANY_OPTIONS' })}
                  selectedOptions={null}
                  forceValueByLocalStorageName='forcedValueClient'
                ></SuperSelect>
                {filteredUser && (
                  <Button
                    variant='primary'
                    type='button'
                    className='ms-3'
                    onClick={() => {
                      setFilteredUser('')
                      removeSavedFilter('filteredUser')
                    }}
                  >
                    {intl.formatMessage({ id: 'MY_ORDERS_FILTER_CLEAR' })}
                  </Button>
                )}
              </div>
            </Col>
          </Row>
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body py-3'>
          {selectedOrders && selectedOrders.length > 0 && (
            <div className={`d-flex justify-content-end align-items-center`}>
              <Button
                variant='primary'
                onClick={validateSelectedOrders}
                className={`d-flex align-items-center ${validatingOrder ? 'disabled' : ''}`}
              >
                {intl.formatMessage({ id: 'VALIDATE_ORDERS.ROW_VALIDATE_SELECTED' })}
                {validatingOrder && <Spinner animation='border' className='mx-3'></Spinner>}
              </Button>
            </div>
          )}
          {/* begin::Table container */}
          <div className='table-responsive'>
            {/* begin::Table */}
            <table
              className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'
              id='table_validate_orders'
            >
              {/* begin::Table head */}
              <thead>
                <tr className='fw-bold text-muted'>
                  <th className='w-25px'>
                    {!noNeedCheckSelect() && (
                      <div className='form-check form-check-sm form-check-custom form-check-solid'>
                        <input
                          className='form-check-input form-check-input-manager'
                          type='checkbox'
                          onChange={handleChangeChecked}
                        />
                      </div>
                    )}
                  </th>
                  <th className='min-w-150px text-center'>
                    {intl.formatMessage({ id: 'VALIDATE_ORDERS.HEADER_ORDER_CREATED_DATE' })}
                  </th>
                  <th className='min-w-150px text-center'>
                    {intl.formatMessage({ id: 'VALIDATE_ORDERS.HEADER_ORDER_CREATED_BY' })}
                  </th>
                  <th className='min-w-120px text-center'>
                    {intl.formatMessage({ id: 'GENERAL_DELIVERY_ADDRESS' })}
                  </th>
                  <th className='min-w-120px text-center'>
                    {intl.formatMessage({ id: 'VALIDATE_ORDERS.HEADER_ORDER_DELIVERY_DATE' })}
                  </th>
                  <th className='min-w-120px text-center'>
                    {intl.formatMessage({ id: 'GENERAL_STATUS' })}
                  </th>
                  <th className='min-w-30px max-w-50 text-center'>
                    {intl.formatMessage({ id: 'VALIDATE_ORDERS.HEADER_ORDER_DETAIL' })}
                  </th>
                  <th className='min-w-100px text-end'></th>
                </tr>
              </thead>
              {/* end::Table head */}
              {/* begin::Table body */}
              <tbody>
                {allOrdersFiltered &&
                  allOrdersFiltered()?.orders?.map((order: PendingOrder) => {
                    return (
                      <ValidateOrdersRow
                        refetch={refetchValidateOrders}
                        order={order}
                        key={`validate-orders-row-${order.guidId}`}
                        setOrdersSelection={setOrdersSelection}
                        validatingOrder={validatingOrder}
                        setValidatingOrder={setValidatingOrder}
                        validatingIndividualOrder={validatingIndividualOrder}
                        setValidatingIndividualOrder={setValidatingIndividualOrder}
                      ></ValidateOrdersRow>
                    )
                  })}
              </tbody>
              {/* end::Table body */}
            </table>
            {/* end::Table */}
          </div>
          {/* end::Table container */}
        </div>
        {/* begin::Body */}
      </div>
    </>
  )
}

export { ViewAllOrders }
