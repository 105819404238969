import {FC, useState, useEffect, createContext, useContext} from 'react'
import {LayoutSplashScreen} from '../../../../_metronic/layout/core'
import {AuthModel} from './_models'
import * as authHelper from './AuthHelpers'
import {WithChildren} from '../../../../_metronic/helpers'
import {requestRenewToken} from './_requests'
import {useQueryClient} from 'react-query'
import {Client} from '../../../models/models'

type AuthContextProps = {
  auth: AuthModel | undefined
  saveAuth: (auth: AuthModel | undefined) => void
  logout: () => void
}

const initAuthContextPropsState = {
  auth: authHelper.getAuth(),
  saveAuth: () => {},
  logout: () => {},
}

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState)

const useAuth = () => {
  return useContext(AuthContext)
}

const AuthProvider: FC<WithChildren> = ({children}) => {
  const queryClient = useQueryClient()
  const [auth, setAuth] = useState<AuthModel | undefined>(authHelper.getAuth())
  const saveAuth = (auth: AuthModel | undefined) => {
    setAuth(auth)
    if (auth) {
      authHelper.setAuth(auth)
    } else {
      authHelper.removeAuth()
    }
  }

  const logout = () => {
    saveAuth(undefined)
    const selectedClient = queryClient.getQueryData(['selectedUser']) as Client
    if (selectedClient) {
      localStorage.removeItem('products' + selectedClient.id)
    }
    localStorage.removeItem('selectedClientID')
    queryClient.clear()
  }

  return <AuthContext.Provider value={{auth, saveAuth, logout}}>{children}</AuthContext.Provider>
}

const AuthInit: FC<WithChildren> = ({children}) => {
  const {auth, logout} = useAuth()
  const [showSplashScreen, setShowSplashScreen] = useState(true)
  // We should request user by authToken (IN OUR EXAMPLE IT'S API_TOKEN) before rendering the application
  useEffect(() => {
    if (auth && auth.token) {
      const nowDate = new Date()
      const limitDate = new Date(auth.expiration)
      if (nowDate > limitDate) {
        authHelper.removeAuth()
        logout()
      }
      limitDate.setHours(limitDate.getHours() - 1)
      const preExpiredToken: boolean = nowDate > limitDate
      if (preExpiredToken) {
        // force expire
        renewToken()
          .then((auth) => {
            authHelper.setAuth(auth)
            setShowSplashScreen(false)
          })
          .catch(() => {
            authHelper.removeAuth()
            logout()
          })
      }
      setShowSplashScreen(false)
      localStorage.removeItem('lastOrderView')
    } else {
      logout()
      setShowSplashScreen(false)
    }
    // eslint-disable-next-line
  }, [])

  const renewToken = async () => {
    window['showToastOnError'] = false
    const {data: auth} = await requestRenewToken()
    window['showToastOnError'] = true
    return auth
  }

  return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>
}

export {AuthProvider, AuthInit, useAuth}
