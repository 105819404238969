export const SaveFilterForcedValue = (optionValue, nameForce, removeAfterGet = true) => {
  localStorage.setItem(nameForce, optionValue + '')
  if (removeAfterGet) {
    setTimeout(() => {
      localStorage.removeItem(nameForce)
    }, 1000)
  }
}
export const GetFilterForcedValue = (nameForce) => {
  return localStorage.getItem(nameForce)
}
export const RemoveFilterForcedValue = (nameForce) => {
  localStorage.removeItem(nameForce)
}
