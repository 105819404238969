export const getProductFormat = (
  packageUnit: string,
  stockRatio?: number,
  ofText = '',
  productSku = ''
) => {
  const of = ofText //intl.formatMessage({id: 'GENERAL.OF'});
  let format = ''
  if (packageUnit.startsWith('c')) {
    format = 'CAJ'
  } else {
    format = packageUnit
  }
  if (stockRatio) {
    /*if (productSku && (productSku === 'AA5B00023' || productSku === 'AA5B00024')) {
      format = `CAJ de 6 UN`
    } else {
      format += ` ${of} ${stockRatio} ${packageUnit.startsWith('c') ? 'UN' : 'KG'}`
    }*/
    format += ` ${of} ${stockRatio} ${packageUnit.startsWith('c') ? 'UN' : 'KG'}`
  }
  return [format]
}
