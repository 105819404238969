import * as Yup from 'yup'

export interface ICreateOrder {
  selectedClient: string
  shippingAddress: string
  selectedDate: String
}

export interface OrderQuestionsModel {
  completeOrder: boolean
  splitPieces: boolean
}

const createOrderModalQuestions = Yup.object({
  completeOrder: Yup.boolean().required().label('Complete order'),
  splitPieces: Yup.boolean().required().label('Split pieces'),
})

const createOrderSchema = [
  // STEP1
  Yup.object({
    selectedClient: Yup.string().required().label('Selected client'),
  }),
  // STEP 2
  Yup.object({
    shippingAddress: Yup.string().required().label('Shipping address'),
  }),
  // STEP 3
  Yup.object({
    selectedDate: Yup.date().label('Selected date'),
  }),
  // STEP 4
  Yup.object({}),
  // STEP 5
  Yup.object({}),
]

const orderInits: ICreateOrder = {
  selectedClient: '',
  shippingAddress: '',
  selectedDate: '',
}

export {createOrderSchema, orderInits, createOrderModalQuestions}
