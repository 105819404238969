export const configStaleTime = (1000 * 60 * 1) / 2 // 30s
export const productsRequirePreferences = [
  'AFAB00053',
  'AFAB00055',
  'AFAB00005',
  'ACAB00082',
  'ACAB00080',
  'ACAB00081',
  'ACA100063',
  'ACAB00060',
  'ACA100060',
  'ACA100082',
  'ACA100081',
  'ACA100064',
  'AFAB00006',
  'AFAB00073',
  'AFAB00074',
  'AFA00060',
  'AFAB00060',
]
export const defaultDateForValidateOrders = new Date();
