import clsx from 'clsx'
import { KTIcon, toAbsoluteUrl } from '../../../helpers'
import { HeaderUserMenu } from '../../../partials'
import { ToggleCartDrawer } from '../../../partials/layout/cart-drawer/ToggleCartDrawer'

const itemClass = 'ms-1 ms-md-4'
const btnClass = 'btn btn-icon btn-custom btn-icon-muted btn-active-light btn-active-color-primary w-35px h-35px'
const userAvatarClass = 'symbol-35px'
const btnIconClass = 'fs-2'

const Navbar = () => {
  return (
    <nav className='app-navbar flex-shrink-0'>
      <div className={clsx('app-navbar-item align-items-stretch d-none', itemClass)}>
        {/* <Search /> */}
      </div>

      <div className={clsx('app-navbar-item d-none', itemClass)}>
        <div id='kt_activities_toggle' className={btnClass}>
          <KTIcon iconName='chart-simple' className={btnIconClass} />
        </div>
      </div>

      <div className={clsx('app-navbar-item d-none', itemClass)}>
        <div
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          className={btnClass}
        >
          <KTIcon iconName='element-plus' className={btnIconClass} />
        </div>
        {/* <HeaderNotificationsMenu /> */}
      </div>

      <div className={clsx('app-navbar-item d-none', itemClass)}>
        <div className={clsx('position-relative', btnClass)} id='kt_drawer_chat_toggle'>
          <KTIcon iconName='message-text-2' className={btnIconClass} />
          <span className='bullet bullet-dot bg-success h-6px w-6px position-absolute translate-middle top-0 start-50 animation-blink' />
        </div>
      </div>

      <div className={clsx('app-navbar-item d-none', itemClass)}>
        {/* <ThemeModeSwitcher toggleBtnClass={clsx('btn-active-light-primary btn-custom')} /> */}
      </div>

      <div className={clsx('app-navbar-item', itemClass)}>
        {/* cart icon */}
        <div className='engage-toolbar d-flex mx-4'>
          <ToggleCartDrawer />
        </div>   

        <div
          className={clsx('cursor-pointer symbol', userAvatarClass)}
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
        >
        <img src={toAbsoluteUrl('media/avatars/blank.png')} alt='' />
        </div>
        <HeaderUserMenu />
      </div>
    </nav>
  )
}

export {Navbar}
