import { FC } from 'react'
import { HelpDrawer } from './help-drawer/HelpDrawer'
import { CartDrawer } from './cart-drawer/CartDrawer'

const RightToolbar: FC = () => {

  return (
    <>
      <HelpDrawer />
      <CartDrawer />
    </>
  )
}

export {RightToolbar}
