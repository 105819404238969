import {Formik, Form} from 'formik'
import {Button, Row, Spinner} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import {useState} from 'react'
import {Link} from 'react-router-dom'
import {useAdminShoppingCart} from '../../context/AdminShoppingCartContext'
import {useProductsAvailables} from '../../hooks/useProductsAvailables'
import {OrderLine, Product} from '../../models/models'
import {ProductItemAdmin} from '../ProductItemAdmin'
import {usePagination} from '../../hooks/usePagination'
import {OrderEdit} from '../../models/orderEdit'
import {ToastifyCaller, ToastifyType} from '../Toastify'
import {requestModifyOrder} from '../../requests/requests'
import {MyOrder} from '../../models/myOrders'
import { useSelectedExpeditionDate } from '../../hooks/useSelectedExpeditionDate'

export const AddProductToOrderForm = ({
  Order,
  onHide,
  refetch,
}: {
  Order: MyOrder
  onHide: any
  refetch: any
}) => {
  const intl = useIntl()
  const {cartItems} = useAdminShoppingCart()
  const selectedExpeditionDate = useSelectedExpeditionDate(Order.expeditionDate);
  const {availableProducts} = useProductsAvailables(Order.clientId, selectedExpeditionDate)
  if (availableProducts) {
    availableProducts.sort((value) => {
      return value.inPromotion ? -1 : 1
    })
  }
  const similarProducts = (product: Product): Product[] => {
    const similarProductsOrdered = [...availableProducts]
    similarProductsOrdered.sort((a, b) => {
      return a.stockRatio - b.stockRatio
    })
    return similarProductsOrdered.filter((x) => x.code === product.code)
  }
  const availableProductsUnique = () => {
    const products = [] as Product[]
    const alreadyShowed = [] as string[]
    availableProducts &&
      availableProducts.map((product) => {
        if (!alreadyShowed.includes(product.code)) {
          alreadyShowed.push(product.code)
          products.push(product)
        }
        return products
      })
    return products
  }
  const [productSearch, setProductSearch] = useState('')
  const filteredAvailableProducts = (): Product[] => {
    if (productSearch) {
      return availableProductsUnique().filter(
        (x) =>
          x.code.toLowerCase() === productSearch ||
          x.code.toLowerCase().includes(productSearch.toLowerCase()) ||
          x.description.toLowerCase().includes(productSearch.toLowerCase())
      )
    }
    return availableProductsUnique()
  }
  const {
    currentItems,
    handlePaginationNumberClick,
    handlePreviousClick,
    handleNextClick,
    chunkedArray,
    currentPage,
  } = usePagination(availableProductsUnique(), 5)
  const [addingProduct, setAddingProduct] = useState(false)
  return (
    <Formik
      initialValues={{}}
      onSubmit={async () => {
        setAddingProduct(true)
        const orderEditObj = {
          guidId: Order.guidId,
          clientId: Order.clientId,
          expeditionDate: Order.expeditionDate,
          detail: Order.detail,
          lines: Order.lines,
        } as OrderEdit
        cartItems.forEach((item) => {
          const foundIndexLine = orderEditObj.lines.findIndex(
            (x) =>
              x.code === item.code &&
              x.packageUnit === item.packageUnit &&
              x.stockRatio === item.stockRatio
          )
          if (foundIndexLine !== -1) {
            orderEditObj.lines[foundIndexLine].quantity += item.quantity
          } else {
            orderEditObj.lines.push({
              code: item.code,
              packageUnit: item.packageUnit,
              quantity: item.quantity,
              stockRatio: item.stockRatio,
              splitPieceQuestion: item.splitPieceQuestion,
            } as OrderLine)
          }
        })
        await requestModifyOrder(orderEditObj)
        setAddingProduct(false)
        await refetch()
        ToastifyCaller(
          intl.formatMessage({id: 'ORDERS.CREATE_ORDER_UPDATED'}),
          ToastifyType.SUCCESS
        )
        onHide()
      }}
    >
      {({handleSubmit}) => (
        <Form onSubmit={handleSubmit} noValidate={true} placeholder={undefined}>
          <section className='flex-row-fluid overflow-hidden'>
            <div className='container-fluid'>
              <div>
                <h2 className='text-dark mb-8'>
                  {intl.formatMessage({id: 'ORDERS.CREATE_NEW_ORDER_STEP4_TITLE'})}
                </h2>
              </div>

              <div className='d-flex flex-column w-100 mb-8 gap-1'>
                <div className='d-flex flex-column w-100 mb-8'>
                  <Row>
                    {/* INPUT SEARCH */}
                    <div className='position-relative col-12'>
                      <label htmlFor='' className='fs-4 mb-4 fw-normal'>
                        {intl.formatMessage({id: 'ORDERS.CREATE_NEW_ORDER_STEP4_INPUT_LABEL'})}
                      </label>
                      <input
                        type='text'
                        className='form-control form-control-xl form-control-solid mb-3'
                        id='kt_datatable_search_query'
                        onChange={(e) => {
                          setProductSearch(e.target.value)
                        }}
                      />
                    </div>
                  </Row>
                  <Row>
                    <span style={{color: '#99A1B7'}}>
                      {intl.formatMessage({id: 'ORDERS.CREATE_NEW_ORDER_STEP4_INPUT_TEXT'})}
                    </span>
                  </Row>
                </div>
                <div style={{height: 'auto', overflow: 'auto', overflowX: 'hidden'}}>
                  {productSearch.length > 0 &&
                    filteredAvailableProducts() &&
                    filteredAvailableProducts().map((product) => {
                      return (
                        <ProductItemAdmin
                          product={product}
                          productsSameType={similarProducts(product)}
                          key={`${product.code}-${product.stockRatio}`}
                        ></ProductItemAdmin>
                      )
                    })}
                  {productSearch.length <= 0 &&
                    currentItems &&
                    currentItems.map((product) => {
                      return (
                        <ProductItemAdmin
                          product={product}
                          productsSameType={similarProducts(product)}
                          key={`${product.code}-${product.stockRatio}`}
                        ></ProductItemAdmin>
                      )
                    })}
                </div>
              </div>

              {/* PAGINATION COMPONENT */}
              {productSearch.length <= 0 && (
                <ul className='pagination'>
                  <li className='page-item previous' onClick={() => handlePreviousClick()}>
                    <Link to={'#'} className='page-link'>
                      <i className='previous' />
                    </Link>
                  </li>

                  {chunkedArray?.map((_newsChunk: any, index: number) => (
                    <li
                      onClick={() => handlePaginationNumberClick(index + 1)}
                      key={index}
                      className={`page-item ${currentPage === index + 1 ? 'active' : ''}`}
                    >
                      <Link to={'#'} className='page-link'>
                        {index + 1}
                      </Link>
                    </li>
                  ))}

                  <li className='page-item next' onClick={() => handleNextClick()}>
                    <Link to={'#'} className='page-link'>
                      <i className='next' />
                    </Link>
                  </li>
                </ul>
              )}
            </div>
          </section>

          <div className='d-flex'>
            <Button
              variant='secondary'
              type='reset'
              className={`w-100 mt-8 ${addingProduct ? 'disabled' : ''}`}
              onClick={onHide}
            >
              {intl.formatMessage({id: 'BUTTON.CANCEL'})}
            </Button>
            <Button
              variant='primary'
              type='submit'
              className={`w-100 mt-8 ms-3 d-flex align-items-center justify-content-center ${
                addingProduct ? 'disabled' : ''
              }`}
            >
              {intl.formatMessage({id: 'BUTTON.ACCEPT'})}
              {addingProduct && <Spinner animation='border' className='ms-3'></Spinner>}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  )
}
