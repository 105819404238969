import {FC} from 'react'
import {useIntl} from 'react-intl'
import {
  CartPriceResponse,
  Product,
  CartLineResponse,
  CartItem,
} from '../../../../models/models'
import {useQueryClient} from 'react-query'
import {useShoppingCart} from '../../../../context/ShoppingCartContext'
import {formatPrice} from '../../../../helpers/formatPrice'
import {getProductFormat} from '../../../../hooks/getProductFormat'
import {Spinner} from 'react-bootstrap'
import { useSelectedExpeditionDate } from '../../../../hooks/useSelectedExpeditionDate'
import { useProductsAvailablesForSelectedClient } from '../../../../hooks/useProductsAvailablesForSelectedClient'

const StepOrder5: FC = () => {
  const intl = useIntl()
  const queryClient = useQueryClient()
  const cartPriceResponse: CartPriceResponse = queryClient.getQueryData([
    'availablePrices',
  ]) as CartPriceResponse
  const {cartItems, cartPricing} = useShoppingCart()
  //const selectedClient: Client = queryClient.getQueryData(['selectedUser']) as Client
    const selectedExpeditionDate = useSelectedExpeditionDate();
    const {availableProducts, selectedClient} = useProductsAvailablesForSelectedClient(selectedExpeditionDate)
  /*const availableProducts: Product[] = JSON.parse(
    selectedClient ? (localStorage.getItem('productsForSelectedClient' + selectedClient.id) as string) : '[]'
  )*/
  const productInfo = (code: string): Product => {
    const products: Product[] =
      availableProducts && availableProducts?.filter((product) => product.code === code)
    return availableProducts && products[0]
  }

  const getCartResponseItem = (code: string, stockRatio: number) => {
    return cartPriceResponse?.lines?.find(
      (x) => x.code === productInfo(code).code && x.stockRatio === stockRatio
    )
  }

  const getCartResponseItemDiscount1 = (cartItem: CartItem) => {
    if (!cartItem) {
      return 0
    }
    const cartLineResp = getCartResponseItem(cartItem.code, cartItem.stockRatio)
    return cartLineResp?.discountValue1
  }

  const getCartResponseItemDiscount2 = (cartItem: CartItem) => {
    if (!cartItem) {
      return 0
    }
    const cartLineResp = getCartResponseItem(cartItem.code, cartItem.stockRatio)
    return cartLineResp?.discountValue2
  }

  return (
    <div className='card card-flush py-4 flex-row-fluid overflow-hidden'>
      {/* begin::Card header*/}
      <div className='card-header'>
        <div className='card-title'>
          <h2 className='text-dark mb-8'>
            {intl.formatMessage({id: 'ORDERS.CREATE_NEW_ORDER_STEPPER5_TITLE'})}
          </h2>
        </div>
      </div>
      {/* end::Card header*/}
      {/* begin::Card body*/}
      <div className='card-body pt-0'>
        {!cartPricing && (
          <>
            {intl.formatMessage({id: 'ORDER.STATUS.CALCULATING_PRICES'})}
            <Spinner animation='border'></Spinner>{' '}
          </>
        )}
        {cartPricing && (
          <div className='table-responsive'>
            {/* begin::Table*/}
            <table className='table align-middle table-row-dashed fs-6 gy-5 mb-0'>
              <thead style={{color: '#99A1B7'}}>
                <tr className='text-start text-gray-400 fw-bold fs-7  text-uppercase gs-0'>
                  <th className='min-w-175px'>
                    {intl.formatMessage({id: 'MY_ORDER_DETAILS.TABLE_HEADER_PRODUCT'})}
                  </th>
                  <th className='min-w-70px text-end'>
                    {intl.formatMessage({id: 'MY_ORDER_DETAILS.TABLE_HEADER_FORMAT'})}
                  </th>
                  <th className='min-w-70px text-end'>
                    {intl.formatMessage({id: 'MY_ORDER_DETAILS.TABLE_HEADER_UNITS'})}
                  </th>
                  <th className='min-w-100px text-end'>
                    {intl.formatMessage({id: 'MY_ORDER_DETAILS.TABLE_HEADER_UNIT_PRICE'})}
                  </th>
                  <th className='min-w-100px text-end'>
                    {intl.formatMessage({id: 'MY_ORDER_DETAILS.TABLE_HEADER_DISCOUNT'})}
                  </th>
                  <th className='min-w-100px text-end'>
                    {intl.formatMessage({id: 'MY_ORDER_DETAILS.TABLE_HEADER_TOTAL'})}
                  </th>
                </tr>
              </thead>
              <tbody className='fw-semibold text-gray-600'>
                {cartItems &&
                  cartItems.map((item) => {
                    if (!productInfo(item.code)) return <></>
                    return (
                      <tr key={`${item.code}${item.packageUnit}${item.quantity}${item.stockRatio}`}>
                        {/* Img + Info */}
                        <td>
                          <div className='d-flex align-items-center gap-4'>
                            <img
                              src={productInfo(item.code).urlImage}
                              className='rounded'
                              alt=''
                              width={50}
                              height={50}
                            />
                            <h4 className='mb-0'>{productInfo(item.code).description}</h4>
                          </div>
                        </td>
                        {/* Format */}
                        <td className='text-end'>
                          {getProductFormat(
                            item.packageUnit,
                            item.stockRatio,
                            intl.formatMessage({id: 'GENERAL.OF'}),
                            item.code
                          )}
                        </td>
                        {/* Units */}
                        <td className='text-end'>{item.quantity}</td>
                        {/* Unit price */}
                        <td className='text-end'>
                          {cartPriceResponse &&
                            getCartResponseItem(item.code, item.stockRatio) && (
                              <span>
                                {getCartResponseItem(item.code, item.stockRatio)!
                                  .netPricePerUnit !==
                                  getCartResponseItem(item.code, item.stockRatio)!
                                    .grossPricePerUnit && (
                                  <>
                                    {formatPrice(
                                      getCartResponseItem(item.code, item.stockRatio)!
                                        .netPricePerUnit
                                    ) + ' '}
                                    <span className='text-decoration-line-through'>
                                      (
                                      {formatPrice(
                                        getCartResponseItem(item.code, item.stockRatio)!
                                          .grossPricePerUnit
                                      )}
                                      )
                                    </span>
                                  </>
                                )}
                                {getCartResponseItem(item.code, item.stockRatio)!
                                  .netPricePerUnit ===
                                  getCartResponseItem(item.code, item.stockRatio)!
                                    .grossPricePerUnit && (
                                  <>
                                    {formatPrice(
                                      getCartResponseItem(item.code, item.stockRatio)!
                                        .netPricePerUnit
                                    )}
                                  </>
                                )}
                              </span>
                            )}{' '}
                          / {item.packageUnit.startsWith('c') ? 'UN' : 'KG'}
                        </td>
                        {/* Discount */}
                        <td className='text-end'>
                          {cartPriceResponse && (
                            <span>
                              {getCartResponseItemDiscount1(item)! <= 0 && <div>-</div>}
                              {getCartResponseItemDiscount1(item)! > 0 &&
                                getCartResponseItemDiscount2(item)! <= 0 && (
                                  <div>{getCartResponseItemDiscount1(item)}</div>
                                )}
                              {getCartResponseItemDiscount1(item)! > 0 &&
                                getCartResponseItemDiscount2(item)! > 0 && (
                                  <div>
                                    {getCartResponseItemDiscount1(item)} /{' '}
                                    {getCartResponseItemDiscount2(item)}
                                  </div>
                                )}
                            </span>
                          )}
                        </td>
                        {/* Total */}
                        <td className='text-end'>
                          {cartPriceResponse && (
                            <span>
                              {(
                                cartPriceResponse?.lines?.find(
                                  (x) =>
                                    x.code === productInfo(item.code).code &&
                                    x.stockRatio === item.stockRatio
                                ) as CartLineResponse
                              )?.netPrice
                                ? formatPrice(
                                    (
                                      cartPriceResponse?.lines?.find(
                                        (x) =>
                                          x.code === productInfo(item.code).code &&
                                          x.stockRatio === item.stockRatio
                                      ) as CartLineResponse
                                    )?.netPrice
                                  )
                                : null}
                            </span>
                          )}
                        </td>
                      </tr>
                    )
                  })}
                <tr>
                  <td colSpan={5} className='text-end'>
                    {intl.formatMessage({id: 'ORDERS.CREATE_NEW_ORDER_STEPPER5_SUBTOTAL'})}
                  </td>
                  <td className='text-end'>
                    {cartPriceResponse &&
                      Object.keys(cartPriceResponse).length > 0 &&
                      formatPrice(
                        cartPriceResponse?.lines.reduce(
                          (acc, currentValue) => acc + currentValue.netPrice,
                          0
                        )
                      )}
                  </td>
                </tr>
                <tr>
                  <td colSpan={5} className='text-end'>
                    {intl.formatMessage({id: 'ORDERS.CREATE_NEW_ORDER_STEPPER5_DISCOUNT'})}
                  </td>
                  <td className='text-end'>
                    {cartPriceResponse &&
                      Object.keys(cartPriceResponse).length > 0 &&
                      cartPriceResponse.comercialDiscount}{' '}
                    %
                  </td>
                </tr>
                <tr>
                  <td colSpan={5} className='fs-3 text-dark text-end'>
                    {intl.formatMessage({id: 'ORDERS.CREATE_NEW_ORDER_STEPPER5_TOTAL'})}
                  </td>
                  <td className='text-dark fs-3 fw-bolder text-end'>
                    {cartPriceResponse &&
                      Object.keys(cartPriceResponse).length > 0 &&
                      formatPrice(cartPriceResponse.finalAmount)}
                  </td>
                </tr>
              </tbody>
            </table>
            {/* end::Table*/}
          </div>
        )}
      </div>
      {/* end::Card body*/}
    </div>
  )
}

export {StepOrder5}
