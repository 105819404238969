import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Toastify = () => {
  return (
    <div>
        <ToastContainer />
    </div>
  );
};

const enum ToastifyType {
  DEFAULT,
  INFO,
  SUCCESS,
  WARNING,
  ERROR
}

const ToastifyCaller = (message, type = ToastifyType.DEFAULT, position?:string, autoClose?:number, hideProgressBar?:boolean, closeOnClick?:boolean, pauseOnHover?:boolean, draggable?:boolean, theme?:string, progress?:any) => {
  const extraArgs = {
    position: position ? position : "top-right",
    autoClose: autoClose ? autoClose : 5000,
    hideProgressBar: hideProgressBar ? hideProgressBar : false,
    closeOnClick: closeOnClick ? closeOnClick : true,
    pauseOnHover: pauseOnHover ? pauseOnHover : false,
    draggable: draggable ? draggable : true,
    progress: progress ? progress : undefined,
    theme: theme ? theme : "light",
  };
  const ToastContent = () => (
    <div>
      {message.replaceAll('</br>', '\n').replaceAll('<br/>', '\n').split('\n').map((line) => (
        <>
          {line}
          <br />
        </>
      ))}
    </div>
  );
  switch (type) {
    case ToastifyType.DEFAULT: {
      return toast(<ToastContent/>, extraArgs as {});
    }
    case ToastifyType.INFO: {
      return toast.info(<ToastContent/>, extraArgs as {});
    }
    case ToastifyType.SUCCESS: {
      return toast.success(<ToastContent/>, extraArgs as {});
    }
    case ToastifyType.WARNING: {
      return toast.warning(<ToastContent/>, extraArgs as {});
    }
    case ToastifyType.ERROR: {
      return toast.error(<ToastContent/>, extraArgs as {});
    }
  }
};

export {Toastify, ToastifyCaller, ToastifyType};