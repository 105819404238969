import axios from 'axios'
import {
  CartPrice,
  CartPriceResponse,
  Client,
  DefaultExpeditionDate,
  Product,
  NewsModel,
  PublicNewsModel,
  EditNewsModel,
  AddNewsModel,
  ViewOrdersModel,
  Category,
  AddCategoryModel,
  EditCategoryModel,
  CategoryWithLanguages,
  UserProfile,
  OrderWithDetail,
} from '../models/models'
import {MyOrder, OrderGroupedByShippingAddress} from '../models/myOrders'
import {
  AllOrdersWithFilterModel,
  CustomerWithID,
  InvoicePackingListResponse,
} from '../models/allOrdersWithFilters'
import {
  ProductExtension,
  ProductExtensionAdd,
  ProductExtensionEdit,
  ProductExtensionSingle,
} from '../models/productExtensions'
import {OrderEdit} from '../models/orderEdit'
import {DashboardInfo} from '../models/dashboard'
import { inputDateFormat } from '../helpers/formatDate'
import { ExportOrderItem } from '../models/exportOrders'

const {VITE_APP_API_URL:API_URL} = import.meta.env

export const CUSTOMER_CLIENTS = `${API_URL}/Customer/Clients`
export const SHIPPING_ADDRESSES = `${API_URL}/ShippingAddress/Get`
export const PRODUCTS = `${API_URL}/Product/Products`
export const DELIVERY_DATE = `${API_URL}/Cart/ExpeditionDate`
export const CART_PRICE = `${API_URL}/Cart/CartPrice`
export const ORDER_SEND = `${API_URL}/Order/NewOrder`
export const EXPORT_ORDERS = `${API_URL}/Customer/AllOrders`
export const MY_LAST_ORDERS = `${API_URL}/Customer/Orders`
export const MY_ORDER_DETAIL = `${API_URL}/Customer/OrderById`
export const ORDER_DETAIL = `${API_URL}/Order/Order`
export const DELETE_ORDER_BY_ID = `${API_URL}/Order/DeleteOrder`
export const ALL_PENDING_ORDERS = `${API_URL}/Order/ManageOrders`
export const MODIFY_ORDER = `${API_URL}/Order/Modify`
export const NEWS = `${API_URL}/News/GetNews`
export const PUBLIC_NEWS = `${API_URL}/News/GetPublicNews`
export const EDIT_NEWS = `${API_URL}/News/Edit`
export const ADD_NEWS = `${API_URL}/News/Add`
export const DELETE_NEWS = `${API_URL}/News/Delete`
export const ALL_ORDERS_WITH_FILTERS = `${API_URL}/Order/Orders`
export const ALL_USERS = `${API_URL}/Account/Users`
export const ALL_DISTRIBUTORS = `${API_URL}/Account/Distributors`
export const CONFIRM_ORDERS = `${API_URL}/Order/ConfirmOrders`
export const CATEGORIES = `${API_URL}/Category/Categories`
export const CATEGORY = `${API_URL}/Category`
export const ADD_CATEGORY = `${API_URL}/Category/Add`
export const EDIT_CATEGORY = `${API_URL}/Category/Edit`
export const DELETE_CATEGORIES = `${API_URL}/Category/Delete`
export const PRODUCT_EXTENSIONS = `${API_URL}/ProductExtension/GetProducts`
export const PRODUCT_EXTENSION = `${API_URL}/ProductExtension`
export const PRODUCT_EXTENSION_EDIT = `${API_URL}/ProductExtension/Edit`
export const PRODUCT_EXTENSION_ADD = `${API_URL}/ProductExtension/Add`
export const PRODUCT_EXTENSION_DELETE = `${API_URL}/ProductExtension/Delete`
export const MY_PROFILE = `${API_URL}/Account/CurrentUser`
export const INFO_DASHBOARD = `${API_URL}/Info/Dashboard`
export const ORDER_INVOICE = `${API_URL}/Order/Invoice`
export const ORDER_PACKING_LIST = `${API_URL}/Order/PackingList`

export function customerClients() {
  return axios.get<Client[]>(CUSTOMER_CLIENTS)
}

export function customerAddress(clientId: string) {
  return axios.get<Client[]>(SHIPPING_ADDRESSES + '/' + clientId)
}

export function requestProducts(clientId: string, date: Date) {
  return axios.get<Product[]>(PRODUCTS + '/' + clientId + '' + '/'+ inputDateFormat(date))
}

export function requestExpeditionDate() {
  return axios.get<DefaultExpeditionDate>(DELIVERY_DATE)
}

export function requestCartPrice(cartPrice: CartPrice) {
  return axios.post<CartPriceResponse>(CART_PRICE, cartPrice)
}

export function requestOrderSend(order: OrderWithDetail) {
  return axios.post<OrderWithDetail>(ORDER_SEND, order)
}

export function requestProfile() {
  return axios.get<UserProfile>(`${MY_PROFILE}`)
}

export function requestMyLastOrders() {
  return axios.get<MyOrder[]>(`${MY_LAST_ORDERS}`)
}

export function requestMyLastOrdersGroupedByAddress(expeditionDateYMD: string) {
  return axios.get<OrderGroupedByShippingAddress[]>(`${MY_LAST_ORDERS}/${expeditionDateYMD}`)
}

export function requestMyOrders(dateYMD: string) {
  return axios.get<MyOrder[]>(`${MY_LAST_ORDERS}?date=${dateYMD}`)
}

export function requestMyOrderDetail(orderId: string) {
  return axios.get<MyOrder>(`${MY_ORDER_DETAIL}/${orderId}`)
}

export function requestOrderDetail(orderId: string) {
  return axios.get<MyOrder>(`${ORDER_DETAIL}/${orderId}`)
}

export function requestAllOrdersWithFilters(customerId?: string, dateYMD?: string) {
  const params = {
    params: {
      date: dateYMD,
      customerId: customerId,
    },
  }
  const getWithParams = customerId || dateYMD ? params : undefined
  return axios.get<AllOrdersWithFilterModel>(ALL_ORDERS_WITH_FILTERS, getWithParams)
}

export function requestAllUsers() {
  return axios.get<CustomerWithID[]>(ALL_USERS)
}

export function requestAllDistributors() {
  return axios.get<CustomerWithID[]>(ALL_DISTRIBUTORS)
}

export function requestDeleteOrder(orderId: string) {
  return axios.delete<number>(`${DELETE_ORDER_BY_ID}/${orderId}`)
}

export function requestAllOrders(dateYmd: Date) {
  return axios.get<ViewOrdersModel>(`${ALL_PENDING_ORDERS}/${dateYmd.toISOString().split('T')[0]}`)
}

export function requestModifyOrder(order: OrderEdit) {
  return axios.put<OrderEdit>(MODIFY_ORDER, order)
}

export function confirmOrders(orderIds: string[]) {
  return axios.post(CONFIRM_ORDERS, orderIds)
}

// News List (Public)
export function requestPublicNews() {
  return axios.get<PublicNewsModel[]>(PUBLIC_NEWS)
}

// News Management
export function requestNews() {
  return axios.get<NewsModel[]>(NEWS)
}

export function addNewsModel(news: AddNewsModel) {
  return axios.post<AddNewsModel>(ADD_NEWS, news)
}

export function editNewsModel(news: EditNewsModel) {
  return axios.put<EditNewsModel>(EDIT_NEWS, news)
}

export function deleteNews(id: number) {
  return axios.delete(DELETE_NEWS + '?id=' + id)
}

// Categories Management
export function requestCategories() {
  return axios.get<Category[]>(CATEGORIES)
}
export function requestCategory(id: number) {
  return axios.get<CategoryWithLanguages>(CATEGORY + '/' + id)
}
export function deleteCategory(id: number) {
  return axios.delete(`${DELETE_CATEGORIES}?id=${id}`)
}
export function addCategory(category: AddCategoryModel) {
  return axios.post<AddCategoryModel>(ADD_CATEGORY, category)
}
export function editCategory(category: EditCategoryModel) {
  return axios.put<EditCategoryModel>(EDIT_CATEGORY, category)
}

// Product Extensions Management
export function requestProductExtensions() {
  return axios.get<ProductExtension[]>(PRODUCT_EXTENSIONS)
}
export function requestProductExtension(id: number) {
  return axios.get<ProductExtensionSingle>(`${PRODUCT_EXTENSION}/${id}`)
}
export function editProductExtension(editProductExtension: ProductExtensionEdit) {
  return axios.put<ProductExtensionEdit>(`${PRODUCT_EXTENSION_EDIT}`, editProductExtension)
}
export function addProductExtension(addProductExtension: ProductExtensionAdd) {
  return axios.post<ProductExtensionEdit>(`${PRODUCT_EXTENSION_ADD}`, addProductExtension)
}
export function deleteProductExtension(id: number) {
  return axios.delete(`${PRODUCT_EXTENSION_DELETE}/?id=${id}`)
}
// INFO DASHBOARD
export const infoDashboard = () => {
  return axios.get<DashboardInfo>(`${INFO_DASHBOARD}`)
}
// Order Invoice
export const orderInvoice = (orderId: string, invoiceId: string) => {
  return axios.get<InvoicePackingListResponse>(`${ORDER_INVOICE}/${orderId}/${invoiceId}`)
}
// Packaging List
export const orderPackagingList = (orderId: string, invoiceId: string) => {
  return axios.get<InvoicePackingListResponse>(`${ORDER_PACKING_LIST}/${orderId}/${invoiceId}`)
}

// Export orders
export async function exportOrders(startDate:string = null, endDate:string = null, clientId:string = null) {
  let params = [];
  if (startDate) {
    params.push(`initDate=${startDate}`);
  }
  if (endDate) {
    params.push(`endDate=${endDate}`);
  }
  if (clientId && clientId.length > 0) {
    params.push(`sageClient=${clientId}`);
  }
  const requestParams = (numPage:number) => { return `${EXPORT_ORDERS}?${params.length > 0 ? params.join('&')+'&' : ''}RecordsPerPage=200&Page=${numPage}` };
  const result = await axios.get<ExportOrderItem[]>(`${requestParams(1)}`)
  const totalPages = result.headers['x-totalpages']
  let data = result.data
  let lastPageNumber = 2;
  if (totalPages && totalPages > 1) {
    const result = await axios.get<ExportOrderItem[]>(`${requestParams(lastPageNumber)}`)
    data = [...result.data]
    lastPageNumber++;
  }
  return data
}