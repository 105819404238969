import { Modal } from 'react-bootstrap'
import { AddProductToOrderForm } from './addProductToOrderForm';
import { AdminShoppingCartProvider } from '../../context/AdminShoppingCartContext';
import { MyOrder } from '../../models/myOrders';

export const AddProductToOrderModal = ({Order, show, onHide, refetch}:{Order:MyOrder, show: boolean, onHide: any, refetch:any}) => {
  if (!show) {return (<></>);}

  return (
    <AdminShoppingCartProvider>
      <Modal show={show !== undefined} onHide={onHide} dialogClassName='modal-dialog modal-xl modal-dialog-centered'>
          <Modal.Header className='border-0 position-absolute top-0 end-0 z-index-10' closeButton style={{ zIndex: 100 }}>
          </Modal.Header>
            <Modal.Body className='modal-body py-lg-5 px-lg-10'>
              <AddProductToOrderForm Order={Order} onHide={onHide} refetch={refetch}></AddProductToOrderForm>
            </Modal.Body>
          </Modal>
    </AdminShoppingCartProvider>
  )
}
