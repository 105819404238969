import { FC, useState } from 'react'
import { useIntl } from 'react-intl'
import { Link, useParams } from 'react-router-dom'
import { MyOrder, MyOrderLine } from '../../models/myOrders'
import { requestModifyOrder, requestMyOrderDetail, requestOrderDetail } from '../../requests/requests'
import { useQuery, useQueryClient } from 'react-query'
import { formatPrice } from '../../helpers/formatPrice'
import { KTIcon } from '../../../_metronic/helpers'
import { ConfirmDeleteModal } from '../../components/generic-confirm/Confirm'
import { Button, Col, Row, Spinner } from 'react-bootstrap'
import { AddProductToOrderModal } from '../../components/edit-order/addProductToOrderModal'
import { ToastifyCaller, ToastifyType } from '../../components/Toastify'
import { EditOrderModal } from '../../components/edit-order/editOrderModal'
import { getProductFormat } from '../../hooks/getProductFormat'
import { useAuth } from '../../modules/auth'
import { UpdateShippingAddressModal } from '../../components/update-shipping-address/updateShippingAddressModal'
import { UpdateExpeditionDateModal } from '../../components/update-expedition-date/updateExpeditionDateModal'
import { useGoBack } from '../../hooks/useGoBack'
import { UpdateExternalRefModal } from '../../components/update-external-ref/UpdateExternalRef'

export const OrderEdit: FC = () => {
  const intl = useIntl()
  const { auth } = useAuth()
  const { orderId } = useParams()
  const [showEdit, setShowEdit] = useState<MyOrderLine | undefined>()
  const [deleteShow, setDeleteShow] = useState<MyOrderLine | undefined>()
  const [showAdd, setShowAdd] = useState(false)
  const [changingAttr, setChangingAttr] = useState(false);
  const [deletingOrderLine, setDeletingOrderLine] = useState(false)
  const goBackText = intl.formatMessage({ id: 'ORDERS.CREATE_NEW_ORDER_BUTTON_BACK' })
  const queryClient = useQueryClient()
  const { goBackFn, goBackHistoryFn, hasAnyHistory } = useGoBack()
  const { refetch: refetchOrderEdit, isFetching } = useQuery<MyOrder>(
    [`orderEdit${orderId}`],
    async () => {
      if (auth?.isAdmin) {
        const { data: x } = await requestOrderDetail(orderId as string)
        x.lines.forEach((p: MyOrderLine) => {
          if (p.urlImage == null) {
            p.urlImage =
              'https://img.freepik.com/vector-premium/vector-icono-imagen-predeterminado-pagina-imagen-faltante-diseno-sitio-web-o-aplicacion-movil-no-hay-foto-disponible_87543-11093.jpg'
          }
        })
        return x
      } else {
        const { data: x } = await requestMyOrderDetail(orderId as string)
        x.lines.forEach((p: MyOrderLine) => {
          if (p.urlImage == null) {
            p.urlImage =
              'https://img.freepik.com/vector-premium/vector-icono-imagen-predeterminado-pagina-imagen-faltante-diseno-sitio-web-o-aplicacion-movil-no-hay-foto-disponible_87543-11093.jpg'
          }
        })
        return x
      }
    },
    { staleTime: 60 * 60 * 24 * 365, enabled: !!orderId }
  )
  const myOrder: MyOrder = queryClient.getQueryData([`orderEdit${orderId}`]) as MyOrder

  const handleConfirmDelete = async () => {
    setDeletingOrderLine(true)
    const myOrderUpdated = { ...myOrder }
    myOrderUpdated.lines = myOrder.lines.filter(
      (x) => !(x.code === deleteShow?.code && x.packageUnit === deleteShow?.packageUnit)
    )
    await requestModifyOrder(myOrderUpdated)
    await refetchOrderEdit()
    setDeletingOrderLine(false)
    ToastifyCaller(intl.formatMessage({ id: 'ORDERS.CREATE_ORDER_UPDATED' }), ToastifyType.SUCCESS)
    setDeleteShow(undefined)
  }

  const [showEditShippingAddress, setShowEditShippingAddress] = useState<MyOrder>()
  const handleChangeShippingAddress = () => {
    setShowEditShippingAddress(myOrder)
  }

  const [showEditExpeditionDate, setShowEditExpeditionDate] = useState<MyOrder>()
  const handleShowEditExpeditionDate = () => {
    setShowEditExpeditionDate(myOrder)
  }

  const [showEditExternalRef, setShowEditExternalRef] = useState<MyOrder>()
  const handleShowEditExternalRef = () => {
    setShowEditExternalRef(myOrder)
  }

  return (
    <div className='app-container container-fluid'>
      {myOrder && (
        <AddProductToOrderModal
          Order={myOrder}
          show={showAdd}
          onHide={() => {
            setShowAdd(false)
          }}
          refetch={refetchOrderEdit}
        ></AddProductToOrderModal>
      )}
      <ConfirmDeleteModal
        show={deleteShow === undefined ? false : true}
        onHide={() => setDeleteShow(undefined)}
        onConfirm={handleConfirmDelete}
        title={intl.formatMessage({ id: 'CONFIRM_TEXT' })}
        message={intl.formatMessage({ id: 'BUTTON.CONFIRM_DELETE' })}
        areDeleting={deletingOrderLine}
      />
      {/* Wrapper */}
      <div className='d-flex flex-column gap-7 gap-lg-10'>
        <div>
          <h1>{intl.formatMessage({ id: 'ORDER_EDIT_TITLE' })}</h1>
        </div>
        <div className='d-flex flex-column flex-xl-row gap-7 gap-lg-10'>
          {myOrder && (
            <>
              {/* Client card */}
              <article className='card card-flush py-4 flex-row-fluid position-relative'>
                <div className='position-absolute top-0 end-0 bottom-0 opacity-10 d-flex align-items-center me-5'>
                  <i className='ki-solid ki-two-credit-cart' style={{ fontSize: '14em' }}></i>{' '}
                </div>
                <div className='card-header'>
                  <div className='card-title'>
                    <h3 className='fs-2'>
                      {intl.formatMessage({ id: 'ORDERS.CREATE_NEW_ORDER_DETAILS_CUSTOMER_DATA' })}
                    </h3>
                  </div>
                </div>
                <div className='card-body pt-0'>
                  <span className='fs-4 mb-0'>{myOrder?.detail.clientBusinessName}</span>
                </div>
              </article>

              {/* Adress card */}
              <article className='card card-flush py-4 flex-row-fluid position-relative'>
                <div className='position-absolute top-0 end-0 bottom-0 opacity-10 d-flex align-items-center me-5'>
                  <i className='ki-solid ki-delivery' style={{ fontSize: '14em' }}></i>
                </div>
                <div className='card-header'>
                  <div className='card-title'>
                    <h3 className='fs-2'>
                      {intl.formatMessage({ id: 'ORDERS.CREATE_NEW_ORDER_DETAILS_SHIPPING_ADDRESS' })}
                    </h3>
                  </div>
                </div>
                <div className='card-body pt-0'>
                  {myOrder?.detail.shippingAddressDescription} <br />(
                  {myOrder?.detail.shippingAddressSageCode}) <br />
                  {myOrder?.detail.shippingAddress} <br />
                  {myOrder?.detail.clientPostalCode} - {myOrder?.detail.shippingAddressCity} <br />
                  {myOrder?.detail.shippingAddressCountryName} <br />
                  <br />{' '}
                  <span
                    onClick={handleChangeShippingAddress}
                    className={`cursor-pointer`}
                    style={{ position: 'absolute', top: '50%', left: '70%' }}
                  >
                    <KTIcon iconName='pencil' className='ms-3'></KTIcon>
                  </span>
                  {myOrder && (
                    <UpdateShippingAddressModal
                      show={showEditShippingAddress}
                      onHide={() => {
                        setShowEditShippingAddress(undefined)
                      }}
                      refetch={refetchOrderEdit}
                      key={`update-shiping-address-${myOrder?.guidId}`}
                    />
                  )}
                </div>
              </article>

              {/* Date card */}
              <article className='card card-flush py-4 flex-row-fluid position-relative'>
                <div className='position-absolute top-0 end-0 bottom-0 opacity-10 d-flex align-items-center me-5'>
                  <i className='ki-solid ki-calendar' style={{ fontSize: '14em' }}></i>
                </div>
                <div className='card-header'>
                  <div className='card-title'>
                    <h3 className='fs-2'>
                      {intl.formatMessage({ id: 'ORDERS.CREATE_NEW_ORDER_DETAILS_SHIPPING_DATE' })}
                    </h3>
                  </div>
                </div>
                <div className='card-body pt-0 position-relative'>
                  {new Date(myOrder.expeditionDate).toLocaleDateString('en-GB')} <br />
                  <br />
                  <span
                    onClick={handleShowEditExpeditionDate}
                    className={`cursor-pointer`}
                    style={{ position: 'absolute', top: '0%', left: '50%' }}
                  >
                    <KTIcon iconName='pencil' className='ms-3'></KTIcon>
                  </span>
                  {myOrder && (
                    <UpdateExpeditionDateModal
                      show={showEditExpeditionDate}
                      onHide={() => {
                        setShowEditExpeditionDate(undefined)
                        setChangingAttr(false)
                      }}
                      refetch={refetchOrderEdit}
                      key={`update-expedition-date-${myOrder?.guidId}`}
                      changing={changingAttr}
                      setChanging={setChangingAttr}
                    />
                  )}
                </div>
              </article>

              {/* external ref card */}
              <article className='card card-flush py-4 flex-row-fluid position-relative'>
                <div className='position-absolute top-0 end-0 bottom-0 opacity-10 d-flex align-items-center me-5'>
                  <i className='ki-solid ki-key' style={{ fontSize: '14em' }}></i>
                </div>
                <div className='card-header'>
                  <div className='card-title'>
                    <h3 className='fs-2'>
                      {intl.formatMessage({ id: 'ORDERS.CREATE_NEW_ORDER_DETAILS_EXTERNAL_REF' })}
                    </h3>
                  </div>
                </div>
                <div className='card-body pt-0 position-relative'>
                  {myOrder.detail.customerExternalReference} <br />
                  <br />
                  <span
                    onClick={handleShowEditExternalRef}
                    className={`cursor-pointer`}
                    style={{ position: 'absolute', top: '0%', left: '50%' }}
                  >
                    <KTIcon iconName='pencil' className='ms-3'></KTIcon>
                  </span>
                  {myOrder && (
                    <UpdateExternalRefModal
                      show={showEditExternalRef}
                      onHide={() => {
                        setShowEditExternalRef(undefined)
                        setChangingAttr(false)
                      }}
                      refetch={refetchOrderEdit}
                      key={`update-external-ref-${myOrder?.guidId}`}
                      changing={changingAttr}
                      setChanging={setChangingAttr}
                    />
                  )}
                </div>
              </article>
            </>
          )}
        </div>

        {isFetching && <Spinner animation='border'></Spinner>}

        {!isFetching && (
          <>
            {/* Begin Product list */}
            <div className='card card-flush py-4 flex-row-fluid overflow-hidden'>
              {/* begin::Card header*/}
              <div className='card-header'>
                <div className='card-title'>
                  <h2>{intl.formatMessage({ id: 'MY_ORDER_DETAILS_TITLE' })}</h2>
                </div>
              </div>
              {/* end::Card header*/}
              {/* begin::Card body*/}
              <div className='card-body pt-0'>
                {myOrder && (
                  <EditOrderModal
                    Order={myOrder}
                    show={showEdit}
                    onHide={() => {
                      setShowEdit(undefined)
                    }}
                    refetch={refetchOrderEdit}
                  ></EditOrderModal>
                )}
                <div className='table-responsive'>
                  {/* begin::Table*/}
                  <table className='table align-middle table-row-dashed fs-6 gy-5 mb-0'>
                    <thead>
                      <tr className='text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0'>
                        <th className='min-w-175px'>
                          {intl.formatMessage({ id: 'MY_ORDER_DETAILS.TABLE_HEADER_PRODUCT' })}
                        </th>
                        <th className='min-w-70px text-end'>
                          {intl.formatMessage({ id: 'MY_ORDER_DETAILS.TABLE_HEADER_FORMAT' })}
                        </th>
                        <th className='min-w-70px text-end'>
                          {intl.formatMessage({ id: 'MY_ORDER_DETAILS.TABLE_HEADER_UNITS' })}
                        </th>
                        <th className='min-w-100px text-end'>
                          {intl.formatMessage({ id: 'MY_ORDER_DETAILS.TABLE_HEADER_UNIT_PRICE' })}
                        </th>
                        <th className='min-w-100px text-end'>
                          {intl.formatMessage({ id: 'MY_ORDER_DETAILS.TABLE_HEADER_DISCOUNT' })}
                        </th>
                        <th className='min-w-100px text-end'>
                          {intl.formatMessage({ id: 'MY_ORDER_DETAILS.TABLE_HEADER_TOTAL' })}
                        </th>
                      </tr>
                    </thead>
                    <tbody className='fw-semibold text-gray-600'>
                      {myOrder &&
                        myOrder?.lines &&
                        myOrder?.lines.map((product) => (
                          <tr key={`${product.code}${product.stockRatio}`}>
                            <td>
                              <div className='d-flex align-items-center'>
                                {/* begin::Thumbnail*/}
                                <img
                                  src={product.urlImage}
                                  className='rounded'
                                  alt={product.description}
                                  style={{ width: '50px', height: '50px' }}
                                />
                                {/* end::Thumbnail*/}
                                {/* begin::Title*/}
                                <div className='ms-5'>
                                  <div className='fw-bold text-primary'>{product.description}</div>
                                  <div className='fs-7 text-muted'>{product.code}</div>
                                </div>
                                {/* end::Title*/}
                              </div>
                            </td>
                            <td className='text-end'>
                              {getProductFormat(
                                product.packageUnit,
                                product.stockRatio,
                                intl.formatMessage({ id: 'GENERAL.OF' }),
                                product.code
                              )}
                            </td>
                            <td className='text-end'>{product.quantity}</td>
                            <td className='text-end'>
                              {product.netPricePerUnit !== product.grossPricePerUnit && (
                                <>
                                  {formatPrice(product.netPricePerUnit) + ' '}
                                  <span className='text-decoration-line-through'>
                                    ({formatPrice(product.grossPricePerUnit)})
                                  </span>
                                </>
                              )}
                              {product.netPricePerUnit === product.grossPricePerUnit && (
                                <>{formatPrice(product.netPricePerUnit)}</>
                              )}{' '}
                              / {product.packageUnit.startsWith('c') ? 'UN' : 'KG'}
                            </td>
                            <td className='text-end'>
                              {product.discountValue1 <= 0 && <div>-</div>}
                              {product.discountValue1 > 0 && product.discountValue2 <= 0 && (
                                <div>{product.discountValue1}%</div>
                              )}
                              {product.discountValue1 > 0 && product.discountValue2 > 0 && (
                                <div>
                                  {product.discountValue1}% / {product.discountValue2}%
                                </div>
                              )}
                            </td>
                            <td className='text-end'>
                              {formatPrice(product.netPrice)}
                              <button
                                className='btn border-none bs-3 text-muted ms-3 p-0 mb-2'
                                onClick={() => {
                                  setShowEdit(product)
                                }}
                              >
                                <KTIcon iconName='pencil' className='fs-3'></KTIcon>
                              </button>
                              <button
                                className={`btn border-none bs-3 text-muted ms-3 p-0 mb-2 ${deletingOrderLine ? 'disabled' : ''
                                  }`}
                                onClick={() => {
                                  setDeleteShow(product)
                                }}
                              >
                                <KTIcon iconName='trash' className='fs-3'></KTIcon>
                              </button>
                            </td>
                          </tr>
                        ))}
                      {myOrder && (
                        <>
                          <tr>
                            <td colSpan={5} className='text-end'>
                              {intl.formatMessage({ id: 'MY_ORDER_DETAILS.TABLE_HEADER_PRICE' })}
                            </td>
                            <td className='text-end'>
                              {formatPrice(
                                myOrder?.lines.reduce(
                                  (acc, currentValue) => acc + currentValue.netPrice,
                                  0
                                )
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td colSpan={5} className='text-end'>
                              {intl.formatMessage({ id: 'MY_ORDER_DETAILS.TABLE_HEADER_DISCOUNT' })}
                            </td>
                            <td className='text-end'>{myOrder.detail.comercialDiscount} %</td>
                          </tr>
                          <tr>
                            <td colSpan={5} className='fs-3 text-dark text-end'>
                              {intl.formatMessage({ id: 'MY_ORDER_DETAILS.TABLE_HEADER_TOTAL' })}
                            </td>
                            <td className='text-dark fs-3 fw-bolder text-end'>
                              {formatPrice(myOrder.finalAmount)}
                            </td>
                          </tr>
                        </>
                      )}
                    </tbody>
                  </table>
                  {/* end::Table*/}
                </div>
              </div>
              {/* end::Card body*/}
            </div>
          </>
        )}

        <Row className='justify-content-between align-items-center'>
          <Col className='text-start'>
            {hasAnyHistory && (
              <a href='#goback' onClick={goBackHistoryFn}>
                <Button variant='secondary' type='button'>
                  {goBackText}
                </Button>
              </a>
            )}
            {!hasAnyHistory && (
              <Link to={goBackFn()}>
                <Button variant='secondary' type='button'>
                  {goBackText}
                </Button>
              </Link>
            )}
          </Col>
          {!isFetching && (
            <Col className='text-end'>
              <Button
                variant='primary'
                type='button'
                onClick={() => {
                  setShowAdd(true)
                }}
              >
                {intl.formatMessage({ id: 'PRODUCT_EXTENSIONS.ADD_MODAL_TITLE' })}
              </Button>
            </Col>
          )}
        </Row>
      </div>
    </div>
  )
}
