import {Field, Formik, Form, FormikValues, FormikProps} from 'formik'
import {useEffect, useRef} from 'react'
import {Button, Col, Row} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import { defaultDateForValidateOrders } from '../constants'

export const FilterByDate = ({onSubmit, filteredDate, setFilteredDate}) => {
  const intl = useIntl()
  const formikRef = useRef<FormikProps<FormikValues>>(null)
  const today = new Date()
  const yesterdayDate = () => {
    const yesterdayDate = new Date(today)
    yesterdayDate.setHours(today.getHours() - 24)
    if (yesterdayDate.getDay() === 6) {
      yesterdayDate.setHours(today.getHours() - 24 * 1)
    }
    if (yesterdayDate.getDay() === 0) {
      yesterdayDate.setHours(today.getHours() - 24 * 2)
    }
    return yesterdayDate.toISOString().split('T')[0]
  }
  const tomorrowDate = () => {
    const tomorrowDate = new Date(today)
    tomorrowDate.setHours(today.getHours() + 24)
    if (tomorrowDate.getDay() === 6) {
      tomorrowDate.setHours(today.getHours() + 24 * 2)
    }
    if (tomorrowDate.getDay() === 0) {
      tomorrowDate.setHours(today.getHours() + 24 * 1)
    }
    return tomorrowDate.toISOString().split('T')[0]
  }
  useEffect(() => {
    if (isNaN(filteredDate)) {
      filteredDate = defaultDateForValidateOrders
    }
    if (formikRef && formikRef.current) {
      formikRef.current.setFieldValue('selectedDate', filteredDate.toISOString().split('T')[0])
    }
  }, [filteredDate])
  return (
    <Formik
      innerRef={formikRef}
      enableReinitialize={true}
      initialValues={{selectedDate: today.toISOString().split('T')[0]}}
      onSubmit={async (values) => {
        onSubmit(values)
      }}
    >
      {({setFieldValue}) => (
        <Form placeholder={undefined}>
          <Row>
            <Col xs='12' lg='auto' className='mb-3 mb-lg-0'>
              <Button
                type='button'
                className='d-inline-block w-100 w-lg-auto'
                onClick={() => {
                  setFieldValue('selectedDate', yesterdayDate())
                  setFilteredDate(new Date(yesterdayDate()))
                }}
              >
                {intl.formatMessage({id: 'VALIDATE_ORDERS.FILTER_YESTERDAY'})}
              </Button>
            </Col>
            <Col xs='12' lg='auto' className='mb-3 mb-lg-0'>
              <Field
                type='date'
                name='selectedDate'
                className='form-control w-100 w-lg-auto'
                //value={filteredDate.toISOString().split('T')[0]}
                // onChange={(element) => {
                //   localStorage.removeItem('filteredDate')
                //   setFilteredDate(new Date(element.target.value))
                //   localStorage.setItem('filteredDate', element.target.value)
                // }}
              />
            </Col>
            <Col xs='12' lg='auto' className='mb-3 mb-lg-0'>
              <Button
                type='button'
                className='d-inline-block w-100 w-lg-auto'
                onClick={() => {
                  setFieldValue('selectedDate', tomorrowDate())
                  setFilteredDate(new Date(tomorrowDate()))
                }}
              >
                {intl.formatMessage({id: 'VALIDATE_ORDERS.FILTER_TOMORROW'})}
              </Button>
            </Col>
            <Col xs='12' lg='auto'>
              <Button type='submit' className='d-inline-block w-100 w-lg-auto'>
                {intl.formatMessage({id: 'VALIDATE_ORDERS.FILTER'})}
              </Button>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  )
}
