import {useQuery} from 'react-query'
import {ViewOrdersModel} from '../models/models'
import {useAuth} from '../modules/auth'
import {requestAllOrders} from '../requests/requests'
import {configStaleTime, defaultDateForValidateOrders} from '../constants'

export const useQueryValidateOrders = (filterDate: Date) => {
  const {auth} = useAuth()
  const {isFetching: isFetchingValidateOrders, refetch: refetchValidateOrders} =
    useQuery<ViewOrdersModel>(
      ['ordersToValidate'],
      () => {
        if (isNaN(filterDate as any)) {
          filterDate = defaultDateForValidateOrders;
        }
        if (auth && auth.isAdmin) {
          return requestAllOrders(filterDate).then(({data: x}) => {
            // x.orders.sort(function (a, b) {
            //   return new Date(b.dateCreated).getTime() - new Date(a.dateCreated).getTime()
            // })
            x.summary.forEach((s) => {
              s.orders.sort(function (a, b) {
                const orderObj = x.orders.find((x) => x.guidId === a.orderGuidId)
                const orderObj2 = x.orders.find((x) => x.guidId === b.orderGuidId)
                if (orderObj && orderObj2) {
                  return orderObj?.status - orderObj2?.status
                }
                return 0
              })
            })
            x.orders.sort(function (a, b) {
              return a.status - b.status
            })
            return x
          })
        } else {
          return {} as ViewOrdersModel
        }
      },
      {staleTime: configStaleTime, enabled: !!auth}
    )
  return {isFetchingValidateOrders, refetchValidateOrders, filterDate}
}
