import {useEffect, useState} from 'react'
import {chunkArray} from '../helpers/chunkArray'
import {Product} from '../models/models'
import {CustomerWithID} from '../models/allOrdersWithFilters'

export function usePaginationForWrapper(
  data,
  itemsPerPage: number,
  filterValue?: string,
  filterType?: 'product' | 'customer' | 'customer-all-orders'
) {
  const [currentPage, setCurrentPage] = useState(1)
  const [itemsArray, setItemsArray] = useState(data)
  const [refreshedFirst, setRefreshedFirst] = useState(false)
  const [chunkedArray, setChunkedArray] = useState(chunkArray(itemsArray, itemsPerPage) || [])

  const usingFilterValue = filterValue

  const refreshPagination = () => {
    setItemsArray(data)
    setChunkedArray(chunkArray(data, itemsPerPage))
  }

  if (usingFilterValue) {
    if (!filterType) {
      filterType = 'product'
    }
    if (filterValue.length > 0) {
      if (filterType === 'product') {
        data = data.filter(
          (x: Product) =>
            x.code.includes(filterValue) ||
            x.description.toLowerCase().indexOf(filterValue.toLocaleLowerCase()) >= 0
        )
      }
      if (filterType === 'customer') {
        data = data.filter(
          (x: CustomerWithID) =>
            x.id.includes(filterValue) ||
            x.sageCode.includes(filterValue) ||
            x.businessName.toLowerCase().indexOf(filterValue.toLocaleLowerCase()) >= 0
        )
      }
      if (filterType === 'customer-all-orders') {
        data = data.filter((x) => x.customer.id === filterValue)
      }
    }
  }

  // This is for refresh if not have pages loaded, but are controlling for not run every time
  if (chunkedArray && chunkedArray.length <= 0) {
    if (data && data.length > 0) {
      if (!refreshedFirst) {
        refreshPagination()
        setRefreshedFirst(true)
      }
    }
  }

  const indexOfLastItem = currentPage * itemsPerPage
  const indexOfFirstItem = indexOfLastItem - itemsPerPage
  const currentItems = data?.slice(indexOfFirstItem, indexOfLastItem)

  const handlePaginationNumberClick = async (page: number) => {
    setCurrentPage(page)
  }

  const handlePreviousClick = async () => {
    if (chunkedArray && currentPage > 1) {
      setCurrentPage(currentPage - 1)
    }
  }

  const handleNextClick = async () => {
    if (chunkedArray && currentPage < chunkedArray.length) {
      setCurrentPage(currentPage + 1)
    }
  }

  useEffect(() => {
    if (typeof filterValue !== 'undefined') {
      if (currentPage > 1) {
        handlePaginationNumberClick(1) // Force change to first page when need refresh pagination because a use of filter for search
      }
      refreshPagination()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usingFilterValue])

  return {
    currentItems,
    handlePaginationNumberClick,
    handlePreviousClick,
    handleNextClick,
    chunkedArray,
    refreshPagination,
    currentPage,
  }
}
