import { useQueryClient } from "react-query";
import { DefaultExpeditionDate } from "../models/models";

export const useSelectedExpeditionDate = (existingExpeditionDate?:string) => {
    const queryClient = useQueryClient()
    const minDeliveryDate: DefaultExpeditionDate = queryClient.getQueryData('deliveryDate') as DefaultExpeditionDate

    if (existingExpeditionDate) {
        return new Date(existingExpeditionDate);
    }

    if (localStorage.getItem('selectedExpeditionDate')) {
        return new Date(localStorage.getItem('selectedExpeditionDate'));
    } else if(minDeliveryDate) {
        return new Date(minDeliveryDate.expeditionDate);
    } else {
        return new Date();
    }
}