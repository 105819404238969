import { Button, Modal } from 'react-bootstrap';
import {useIntl} from 'react-intl';
import { ValidateOrdersResponse } from '../../models/models';

export const ConfirmValidatedOrdersModal = ({show, setShow }: {show:any, setShow:any}) => {
  const intl = useIntl();
  const ordersValidated = localStorage.getItem('ordersValidated') && JSON.parse(localStorage.getItem('ordersValidated') as string);
  const allValid = ordersValidated && ordersValidated.filter((x: ValidateOrdersResponse) => !x.value).length <= 0;

  return (
    <Modal show={show} dialogClassName='modal-dialog modal-dialog-centered modal-lg'>
      <div className={`alert alert-dismissible d-flex flex-center flex-column py-10 px-10 px-lg-20 mb-0`}>
        <span className='svg-icon svg-icon-5tx svg-icon-danger mb-5'>
          <i className='bi bi-exclamation-circle text-success fs-4x'></i>
        </span>

        <div className='text-center'>
          <h5 className='fw-bolder fs-1 mb-5'>
            {intl.formatMessage({id: 'CONFIRM_VALIDATED_ORDERS.TITLE'})}
          </h5>

          {allValid && <div className='mb-9 fs-4'>{intl.formatMessage({id: 'CONFIRM_VALIDATED_ORDERS.ALL_OK'})}</div>}

          <div className='mb-9 fs-4'>
              {
                !allValid && ordersValidated && ordersValidated.map((orderValidateResponse:ValidateOrdersResponse, i) => {
                  return <p key={orderValidateResponse.key}>
                    {intl.formatMessage({id: 'CONFIRM_VALIDATED_ORDERS.DETAILS'})} {orderValidateResponse.key} {orderValidateResponse.value ? intl.formatMessage({id: 'CONFIRM_VALIDATED_ORDERS.OK_VALIDATION'}) : intl.formatMessage({id: 'CONFIRM_VALIDATED_ORDERS.ERROR_VALIDATION'})}
                  </p>
                })
              }
          </div>

          <Button onClick={() => {setShow(false);localStorage.removeItem('ordersValidated');}}>{intl.formatMessage({id: 'BUTTON.ACCEPT'})}</Button>
        </div>
      </div>

    </Modal>
  );
};
