import {useIntl} from 'react-intl'
import {KTIcon} from '../../../../_metronic/helpers'
import {StepOrder1} from './steps-orders/StepOrder1'
import {StepOrder2} from './steps-orders/StepOrder2'
import {StepOrder3} from './steps-orders/StepOrder3'
import {StepOrder4} from './steps-orders/StepOrder4'
import {StepOrder5} from './steps-orders/StepOrder5'
import {ICreateOrder, orderInits} from './CreateOrder'
import {useState} from 'react'
import {Form, Formik} from 'formik'
import {useShoppingCart} from '../../../context/ShoppingCartContext'
import ErrorCatcher from '../../../components/ErrorCatcher'

export const OrderStepsForm = ({currentSchema, submitStep, prevStep, stepper, sendingOrder}) => {
  const intl = useIntl()
  const [initValues] = useState<ICreateOrder>(orderInits)
  const {cartPricing} = useShoppingCart()
  return (
    <Formik validationSchema={currentSchema} initialValues={initValues} onSubmit={submitStep}>
      {() => (
        <Form className='p-lg-10 w-100' noValidate id='kt_create_account_form' placeholder={undefined}>
          <div className='current' data-kt-stepper-element='content'>
            <ErrorCatcher>
              <StepOrder1 />
            </ErrorCatcher>
          </div>

          <div data-kt-stepper-element='content'>
            <ErrorCatcher>
              <StepOrder2 />
            </ErrorCatcher>
          </div>

          <div data-kt-stepper-element='content'>
            <ErrorCatcher>
              <StepOrder3 />
            </ErrorCatcher>
          </div>

          <div data-kt-stepper-element='content'>
            <ErrorCatcher>
              <StepOrder4 />
            </ErrorCatcher>
          </div>

          <div data-kt-stepper-element='content'>
            <ErrorCatcher>
              <StepOrder5 />
            </ErrorCatcher>
          </div>

          {/* begin::buttons */}
          <div className='d-flex flex-stack pt-10'>
            <div className='mr-2'>
              <button
                onClick={prevStep}
                type='button'
                className='btn btn-lg btn-light-primary me-3'
                data-kt-stepper-action='previous'
              >
                <KTIcon iconName='arrow-left' className='fs-4 me-1' />
                {intl.formatMessage({id: 'ORDERS.CREATE_NEW_ORDER_BUTTON_BACK'})}
              </button>
            </div>

            {stepper?.current?.totalStepsNumber! > 0 &&
            stepper?.current?.currentStepIndex === stepper?.current?.totalStepsNumber ? (
              <button
                type='submit'
                className={`btn btn-lg btn-primary ${
                  sendingOrder || !cartPricing ? 'disabled' : ''
                }`}
                id='newOrderSubmitFinal'
                onClick={() => submitStep}
              >
                <span className='indicator-label d-flex align-items-center'>
                  {!cartPricing && intl.formatMessage({id: 'ORDER.STATUS.CALCULATING_PRICES'})}
                  {cartPricing && intl.formatMessage({id: 'ORDERS.CREATE_NEW_ORDER_BUTTON_END'})}
                  <KTIcon iconName='arrow-right' className='fs-3 ms-2 me-0' />
                  {sendingOrder && (
                    <span className='spinner-border h-15px w-15px align-middle text-gray-400 mx-3' />
                  )}
                </span>
              </button>
            ) : (
              <button
                type='submit'
                className={`btn btn-lg btn-primary ${sendingOrder ? 'disabled' : ''}`}
                id='newOrderSubmit'
              >
                <span className='indicator-label d-flex align-items-center'>
                  {intl.formatMessage({id: 'ORDERS.CREATE_NEW_ORDER_BUTTON_NEXT'})}
                  <KTIcon iconName='arrow-right' className='fs-3 ms-2 me-0' />
                  {sendingOrder && (
                    <span className='spinner-border h-15px w-15px align-middle text-gray-400 mx-3' />
                  )}
                </span>
              </button>
            )}
          </div>
          {/* end::buttons */}
        </Form>
      )}
    </Formik>
  )
}
