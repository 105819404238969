/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import { useIntl } from 'react-intl'
import { PageTitle } from '../../../_metronic/layout/core'
import DashboardNews from './DashboardNews'
import { DashboardSummmary } from './DashboardSummary'

const DashboardWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
      <div className="d-flex justify-content-between mb-2">
        <div>
          <h1>{intl.formatMessage({id: 'DASHBOARD.TITLE'})}</h1>
        </div>
      </div>
      <DashboardNews />
      <DashboardSummmary/>
    </>
  )
}

export {DashboardWrapper}
