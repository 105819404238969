import { Field, Form, Formik } from "formik";
import { KTIcon } from "../../_metronic/helpers";
import * as Yup from 'yup';
import { Button } from "react-bootstrap";

export const UpdateSageCodeInput = ({defaultValue, onSubmit, editMode = false}) => {
    return (
        editMode ?
        <Formik initialValues={{ sageCode: defaultValue }} onSubmit={onSubmit} validationSchema={Yup.object({
            sageCode: Yup.string().required()
          })}>
            {() => (
            <Form className="d-flex h-100">
                <Field type="text" name="sageCode" className="form-control bg-transparent mw-25 d-inline-block rounded-0 rounded-start h-100"/>
                <Button type="submit" className="border-0 h-100 rounded-0 rounded-end bg-primary h-100">
                    <KTIcon iconName="check" className="text-white"></KTIcon>
                </Button>
            </Form>
            )}
        </Formik>
        :
        <>{defaultValue}</>
  );
}